import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Skeleton } from 'antd';

import styles from './GalleryNavigation.module.scss';

import { IDocument } from '../../../store/documents';
import { useGetAuthFileUrl } from '../../../hooks/useGetAuthFile';
import { LoadingStatus } from '../../../store/types';
import { isDocumentFilePdf } from '../../../utils/commonHelpers';
import { FilePdfOutlined } from '@ant-design/icons';

interface GalleryNavigationItemProps {
  isActive: boolean;
  document: IDocument;
  setActive: () => void;
}

const GalleryNavigationItem: FC<GalleryNavigationItemProps> = ({
  isActive,
  document,
  setActive,
}) => {
  const isPdf = isDocumentFilePdf(document.filename);

  const { fileUrl, loadingStatus } = useGetAuthFileUrl(
    document.file,
    true,
    isPdf
  );

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button && isActive) {
      button.focus();
    }
  }, [buttonRef.current, isActive]);

  if (loadingStatus !== LoadingStatus.SUCCESS) {
    return (
      <div className={styles.loadingDocument}>
        <Skeleton.Image />
      </div>
    );
  }

  return (
    <li
      className={classNames(styles.navigationItem, {
        [styles.navigationItemActive]: isActive,
      })}
      onClick={setActive}
    >
      <button ref={buttonRef} className={styles.button}>
        {isPdf ? (
          <div className={styles.pdf}>
            <FilePdfOutlined />
          </div>
        ) : (
          <img className={styles.navigationImage} src={fileUrl} alt="" />
        )}
      </button>
    </li>
  );
};

export default GalleryNavigationItem;
