import React, { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Slider } from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';

import styles from './GalleryEditImage.module.scss';

import useStore from '../../../hooks/useStore';
import { IDocument } from '../../../store/documents';

interface GalleryEditImageProps {
  rotateCurrentImage: (degree: number) => void;
  zoom: number;
  setZoom: (zoom: any) => void;
  currentDocument: IDocument;
}

const GalleryEditImage: FC<GalleryEditImageProps> = ({
  rotateCurrentImage,
  setZoom,
  zoom,
  currentDocument,
}) => {
  const { documentsStore } = useStore();

  const handleRotateLeft = () => {
    rotateCurrentImage(270);
  };

  const handleRotateRight = () => {
    rotateCurrentImage(90);
  };

  const editDocumentLoading = documentsStore.editGroupDocumentsStatuses.some(
    (id) => id === currentDocument.filename
  );

  const zoomPlusButtonClick = () => {
    setZoom((prev: number) => (prev + 15 >= 100 ? 100 : prev + 15));
  };

  const zoomMinusButtonClick = () => {
    setZoom((prev: number) => (prev - 15 <= 0 ? 0 : prev - 15));
  };

  const sliderRef = useRef<any>(null);

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      const handleScroll = (e: WheelEvent) => {
        slider.focus = false;
        if (e.deltaY > 0) {
          setZoom((prev: number) => (prev - 5 <= 0 ? 0 : prev - 5));
        } else if (e.deltaY < 0) {
          setZoom((prev: number) => (prev + 5 >= 100 ? 100 : prev + 5));
        }
      };
      const handleClick = () => {
        sliderRef.current.sliderRef
          .querySelectorAll('[role="slider"]')
          .forEach((slider: any) => {
            slider.blur();
          });
      };
      slider.sliderRef?.addEventListener('wheel', handleScroll);
      slider.sliderRef?.addEventListener('click', handleClick);
      return () => {
        slider.sliderRef?.removeEventListener('wheel', handleScroll);
        slider.sliderRef?.removeEventListener('click', handleClick);
      };
    }
  }, [sliderRef.current]);

  return (
    <div className={styles.editImage}>
      <div className={styles.zoomButtons}>
        <Button
          type="primary"
          icon={<MinusOutlined />}
          onClick={zoomMinusButtonClick}
        />
        <Slider
          ref={sliderRef}
          className={styles.zoomSlider}
          value={zoom}
          onChange={setZoom}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={zoomPlusButtonClick}
        />
      </div>
      <div className={styles.rotateButtons}>
        <Button
          disabled={editDocumentLoading}
          type="primary"
          icon={<RotateLeftOutlined />}
          onClick={handleRotateLeft}
        />
        <Button
          disabled={editDocumentLoading}
          type="primary"
          icon={<RotateRightOutlined />}
          onClick={handleRotateRight}
        />
      </div>
    </div>
  );
};

export default observer(GalleryEditImage);
