import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Alert } from 'antd';
import Title from 'antd/lib/typography/Title';

import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';
import { IPatient } from '../../store/patients';
import {
  Nav,
  PatientsSearch,
  PatientsTable,
  QRCodeCamera,
} from '../../components';
import { SelectTypes } from '../../components/PatientsSearch';
import PatientsJournal from '../../components/PatientsJournal';

const Patients: FC = () => {
  const history = useHistory();

  const { patientsStore } = useStore();

  const [qrCodeCamera, setQrCodeCamera] = useState<boolean>(false);
  const [isJournal, setIsJournal] = useState<boolean>(true);

  const isLoading = patientsStore.loadingStatus === LoadingStatus.LOADING;
  const errorMessage = `${patientsStore.errorMessage}` || 'Виникла помилка';

  useEffect(() => {
    if (patientsStore.loadingStatus === LoadingStatus.ERROR) {
      patientsStore.setLoadingStatus(LoadingStatus.NEVER);
    }
  }, []);

  useEffect(() => {
    if (patientsStore.loadingStatus === LoadingStatus.ERROR) {
      patientsStore.setPatients([]);
    }
  }, [patientsStore.loadingStatus]);

  const handleSearch = async (value: string, selectType: string) => {
    if (!value.trim()) {
      return false;
    }

    switch (selectType) {
      case SelectTypes.NAME:
        await patientsStore.getByName(value);
        break;
      case SelectTypes.PHONE:
        await patientsStore.getByPhone(value);
        break;
      case SelectTypes.ID:
        await patientsStore.getById(value);
        break;
    }
  };

  const handlePatientClick = (patient: IPatient) => ({
    onClick: () => history.push(`/patients/${patient.patientID}/documents`),
  });

  return (
    <>
      <Title level={3} className={'title'}>
        Пацієнти
      </Title>
      <Nav />
      <PatientsSearch
        onSearch={handleSearch}
        isLoading={isLoading}
        setQr={setQrCodeCamera}
        setJournal={setIsJournal}
      />
      {patientsStore.loadingStatus === LoadingStatus.ERROR && (
        <Alert
          style={{ marginTop: 20 }}
          type={'error'}
          message={errorMessage}
        />
      )}
      {qrCodeCamera ? (
        <QRCodeCamera />
      ) : isJournal ? (
        <PatientsJournal />
      ) : (
        <PatientsTable
          isLoading={isLoading}
          patients={toJS(patientsStore.patients)}
          handleRowClick={handlePatientClick}
        />
      )}
    </>
  );
};

export default observer(Patients);
