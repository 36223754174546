import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import styles from './GalleryItem.module.scss';
import loadingStyles from '../../AuthImage/AuthImage.module.scss';

import { IDocument } from '../../../store/documents';
import { useGetAuthFileUrl } from '../../../hooks/useGetAuthFile';
import useGalleryScrollFlip from '../hooks/useGalleryScrollFlip';
import { isDocumentFilePdf } from '../../../utils/commonHelpers';
import { LoadingStatus } from '../../../store/types';
import { Skeleton } from 'antd';
import ImageMagnifier from '../../ImageMagnifier';

interface GalleryItemProps {
  setPrevDocument: () => void;
  setNextDocument: () => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  currentDocument: IDocument;
}

const isVerticalImage = (width: number, height: number) => {
  return height > width;
};

const GalleryItem: FC<GalleryItemProps> = ({
  setPrevDocument,
  setNextDocument,
  zoom,
  setZoom,
  currentDocument,
}) => {
  const isPdf =
    !!currentDocument.filename && isDocumentFilePdf(currentDocument.filename);
  const { fileUrl, loadingStatus } = useGetAuthFileUrl(
    currentDocument.file,
    false,
    isPdf
  );

  const imageRef = useRef<HTMLImageElement>(null);

  const [imgNaturalSize, setImgNaturalSize] = useState({ width: 0, height: 0 });
  const [imageBoundingWidth, setImageBoundingWidth] = useState(0);
  const [scrollable, setScrollable] = useState(false);
  const [isNewOrientation, seNewOrientation] = useState(false);

  const containerRef = useGalleryScrollFlip({
    setNextDocument,
    setPrevDocument,
    work: !scrollable,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      if (container.scrollHeight > container.clientHeight) {
        setScrollable(true);
      } else {
        setScrollable(false);
      }
    }
  }, [zoom, containerRef.current]);

  useEffect(() => {
    if (!fileUrl) return;
    const img = new Image();
    img.src = fileUrl;
    img.onload = () => {
      setImgNaturalSize({ width: img.width, height: img.height });
      if (
        imgNaturalSize.width === 0 ||
        imgNaturalSize.height === 0 ||
        isVerticalImage(imgNaturalSize.width, imgNaturalSize.height) !==
          isVerticalImage(img.width, img.height)
      ) {
        setZoom(0);
        seNewOrientation(true);
      }
    };
  }, [fileUrl]);

  const style = useMemo(() => {
    if (!imageRef.current) return;

    const actualWidth = imageBoundingWidth * (1 + zoom / 100) + 'px';
    return isVerticalImage(imgNaturalSize.width, imgNaturalSize.height)
      ? zoom
        ? {
            height: 'auto',
            width: actualWidth,
          }
        : {
            height: '100%',
            width: 'auto',
          }
      : { width: zoom ? actualWidth : '50%', height: 'auto' };
  }, [zoom, imageBoundingWidth, imgNaturalSize]);

  useEffect(() => {
    if (imageRef.current && isNewOrientation) {
      const width = imageRef.current.getBoundingClientRect().width;
      setImageBoundingWidth(width);
      seNewOrientation(false);
    }
  }, [imageRef.current, isNewOrientation]);

  return (
    <div className={styles.gallery} ref={containerRef}>
      <div
        className={styles.galleryItem}
        style={zoom ? { height: 'auto' } : { height: '76.95vh' }}
      >
        {loadingStatus === LoadingStatus.LOADING || !fileUrl ? (
          <div className={loadingStyles.loadingImage}>
            <Skeleton.Image />
          </div>
        ) : isPdf ? (
          <iframe src={fileUrl} className={styles.galleryPdf} title="pdf" />
        ) : (
          <ImageMagnifier
            ref={imageRef}
            src={fileUrl || ''}
            style={style}
            className={styles.galleryImage}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryItem;
