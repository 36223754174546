import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import styles from '../Schedule.module.scss';

import { LoadingStatus } from '../../../store/types';
import { EditTableModal } from '../../../components';
import { scheduleTableColumns } from '../consts';
import { TableColumn } from '../types';
import {
  getScheduleTableCheckedColumns,
  saveEditTableCheckedColumns,
} from '../../../utils/tokenHelpers';
import { IScheduleItem } from '../../../store/schedule';
import { timeToDate } from '../../../utils/commonHelpers';

interface ScheduleTableProps {
  scheduleItems: IScheduleItem[];
  loading: LoadingStatus;
  onRowClick?: (scheduleItem: IScheduleItem) => void;
  rowClassName?: (row: IScheduleItem) => string;
}

const ScheduleTable: FC<ScheduleTableProps> = ({
  scheduleItems,
  loading,
  onRowClick,
  rowClassName,
}) => {
  const [editTableModal, setEditTableModal] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState<TableColumn[]>(
    getScheduleTableCheckedColumns()
  );

  const handleEditTable = (checkedColumns: TableColumn[]) => {
    setCheckedColumns(checkedColumns);
    setEditTableModal(false);
    saveEditTableCheckedColumns(checkedColumns);
  };

  const handleRow = (scheduleItem: IScheduleItem) => ({
    onClick: () => onRowClick && onRowClick(scheduleItem),
  });

  const scrollBarHeight = 15;
  const tableRowHeight = 35.85;
  const itemIndexToScroll = scheduleItems.findIndex(
    (el) => timeToDate(el.time).getHours() >= 12
  );
  const YScroll =
    itemIndexToScroll > 0
      ? itemIndexToScroll * tableRowHeight + tableRowHeight + scrollBarHeight
      : 0;

  return (
    <div style={{ position: 'relative' }}>
      <Button
        className={styles.editTableButton}
        icon={<EditOutlined />}
        onClick={() => setEditTableModal(true)}
      />
      <EditTableModal
        visible={editTableModal}
        onOk={handleEditTable}
        onCancel={() => setEditTableModal(false)}
        columnsState={{
          all: scheduleTableColumns,
          checked: checkedColumns.length
            ? checkedColumns
            : scheduleTableColumns,
        }}
      />
      <Table
        size="small"
        style={{ marginTop: 20, whiteSpace: 'nowrap' }}
        rowClassName={rowClassName}
        dataSource={scheduleItems.map((p) => ({
          ...p,
          key: p.time,
        }))}
        columns={checkedColumns.length ? checkedColumns : scheduleTableColumns}
        loading={loading === LoadingStatus.LOADING}
        pagination={false}
        scroll={{ x: true, y: YScroll || undefined }}
        bordered
        onRow={onRowClick && handleRow}
      />
    </div>
  );
};

export default observer(ScheduleTable);
