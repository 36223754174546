import React, { FC, useState } from 'react';
import IMask from 'imask';
import moment from 'moment';

import { Alert, Button, DatePicker, Form, Input } from 'antd';

import styles from '../../Schedule.module.scss';

import { dateFormat } from '../../consts';
import { PatientApi } from '../../../../api/patient';
import { createFullname } from '../../../../utils/commonHelpers';
import { SavedPatient } from './index';

interface PatientFormProps {
  onCreate: ({ patientID, fullname, birthDate }: SavedPatient) => void;
}

const DATE_FORMAT = dateFormat;
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

const PatientForm: FC<PatientFormProps> = ({ onCreate }) => {
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    message: string;
  } | null>(null);

  const handleForm = () => {
    (async () => {
      try {
        setLoading(true);
        setError(null);
        const { patientID } = await PatientApi.create({
          firstname,
          middlename,
          lastname,
          phone,
          email,
          birthDate,
        });
        onCreate({
          patientID: Number(patientID),
          fullname: createFullname({ firstname, middlename, lastname }),
          birthDate,
        });
      } catch (e: any) {
        setError({
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleBirthdate = (value: moment.Moment | null) => {
    if (value) {
      setBirthDate(value.format(dateFormat));
    } else {
      setBirthDate('');
    }
  };

  return (
    <Form className={styles.patientForm} onFinish={handleForm}>
      <Form.Item
        label="Прізвище"
        name="lastname"
        rules={[
          {
            required: true,
            message: 'Заповніть поле',
          },
        ]}
      >
        <Input
          required
          value={middlename}
          onChange={(v) => setLastname(v.target.value)}
        />
      </Form.Item>
      <Form.Item label="Ім'я" name="firstname">
        <Input
          value={firstname}
          onChange={(v) => setFirstname(v.target.value)}
        />
      </Form.Item>
      <Form.Item label="По батькові" name="middlename">
        <Input value={lastname} onChange={(v) => setMiddlename(v.target.value)} />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Введіть коректний e-mail',
          },
        ]}
      >
        <Input value={email} onChange={(v) => setEmail(v.target.value)} />
      </Form.Item>
      <Form.Item label="Номер телефону" name="phone">
        <Input value={phone} onChange={(v) => setPhone(v.target.value)} />
      </Form.Item>
      <Form.Item label="Дата народження" name="birthDate">
        <DatePicker
          format={DATE_FORMAT}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            input.value = MASKED.resolve(input.value);
          }}
          onChange={handleBirthdate}
        />
      </Form.Item>
      {!!error && (
        <Alert
          style={{ marginBottom: 24 }}
          className={styles.errorMessage}
          message="Виникла помилка"
          type="error"
        />
      )}
      <Form.Item>
        <Button
          disabled={loading}
          loading={loading}
          className={styles.submit}
          type="primary"
          htmlType="submit"
        >
          Створити картку пацієнта
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PatientForm;
