import React, { FC, Suspense } from 'react';
import { Loader } from '../index';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';

interface SuspenseRouteProps extends RouteProps {
  component: React.ComponentType
}

const SuspenseRoute:FC<SuspenseRouteProps> = ({component, ...rest}) => {
  const Component = component
  return <Route
    {...rest}
    component={() => (
      <Suspense fallback={<Loader />}>
        <Component />
      </Suspense>
    )}
  />
}

export default SuspenseRoute
