import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { DatePicker, Form, FormInstance, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { dateFormat } from '../../pages/Schedule/consts';
import useStore from '../../hooks/useStore';

interface DocumentInputsProps {
  form: FormInstance;
}

const { Option } = Select;

const DocumentInputs: FC<DocumentInputsProps> = ({ form }) => {
  const { documentsStore } = useStore();

  useEffect(() => {
    (async () => {
      if (!documentsStore.documentTypes.length) {
        documentsStore.getDocumentTypes();
      }
    })();
  }, []);

  return (
    <Form form={form}>
      <Form.Item
        style={{ marginBottom: 5 }}
        label={<b style={{ fontSize: 16 }}>Тип</b>}
        name="type"
      >
        <Select allowClear>
          {documentsStore.documentTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 5 }}
        label={<b style={{ fontSize: 16 }}>Дата</b>}
        name="date"
      >
        <DatePicker format={dateFormat} inputReadOnly />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 5 }}
        label={<b style={{ fontSize: 16 }}>Опис</b>}
        name="desc"
      >
        <TextArea autoSize />
      </Form.Item>
    </Form>
  );
};

export default observer(DocumentInputs);
