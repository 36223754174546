import { FC } from 'react';

import { Typography } from 'antd';

import styles from './CollectionTitle.module.scss';

import { IDocument } from '../../../../../store/documents';
import { SortType } from '../../../hooks/useCurrentDocument';

interface CollectionsTitleProps {
  sortType: SortType;
  collection: IDocument[];
}

const CollectionsTitle: FC<CollectionsTitleProps> = ({
  sortType,
  collection,
}) => {
  return (
    <Typography className={styles.collectionTitle}>
      {`${
        sortType === 'date'
          ? collection[0].documentType || 'Немають типу'
          : collection[0].creationDate
      } (${collection.length})`}
    </Typography>
  );
};

export default CollectionsTitle;