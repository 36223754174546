import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { IDocument } from '../../../store/documents';
import { getRotatedImagesFromUrl } from '../../../utils/canvasImageHelpers';
import useStore from '../../../hooks/useStore';
import { getSortedCollections } from '../../../utils/sortCollectionHelpers';
import {
  sortedCollectionToTwoDimensionalCollections,
  toNextCollection,
  toPrevCollection,
} from '../utils';
import { collectionsToDocuments } from '../../../utils/commonHelpers';

interface UseCurrentDocumentProps {
  collections: IDocument[][];
}

export interface SortedCollection {
  [key: string]: IDocument[][];
}

export type SortType = 'type' | 'date';

export const defaultSortBy: SortType = 'type';

const useCurrentDocument = ({ collections }: UseCurrentDocumentProps) => {
  const { documentsStore, filesStore } = useStore();

  const [sortType, setSortType] = useState<SortType>(defaultSortBy);
  const [sortedCollection, setSortedCollection] = useState<SortedCollection>(
    {}
  );
  const [activeCollectionUUID, setActiveCollectionUUID] = useState<
    string | null
  >(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const activeCollection = useMemo(() => {
    const collections =
      sortedCollectionToTwoDimensionalCollections(sortedCollection);
    if (activeCollectionUUID) {
      return (
        collections.find(
          (collection) => collection[0].collectionUUID === activeCollectionUUID
        ) || collections[0]
      );
    }
    return collectionsToDocuments(collections);
  }, [sortedCollection, activeCollectionUUID]);

  const [currentDocument, setCurrentDocument] = useState({
    ...activeCollection[currentIndex],
  });

  useEffect(() => {
    setSortedCollection(getSortedCollections(collections, sortType));
  }, [collections]);

  useEffect(() => {
    // it covers case when last document is deleted
    if (!activeCollection[currentIndex]) {
      if (currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
      }
    }

    setCurrentDocument({ ...activeCollection[currentIndex] });
  }, [activeCollection, currentIndex]);

  const handleSelectType = (type: SortType) => {
    setSortType(type);
    setSortedCollection(getSortedCollections(collections, type));
  };

  const setActiveCollectionAndIndex = useCallback(
    (collectionUUID: string | null, index: number) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setActiveCollectionUUID(collectionUUID);
        setCurrentIndex(index);
      });
    },
    []
  );

  const nextDocument = useCallback(() => {
    const { move, nextCollection } = toNextCollection(
      sortedCollection,
      currentDocument
    );
    if (move && nextCollection) {
      setActiveCollectionAndIndex(nextCollection[0].collectionUUID, 0);
    } else {
      setCurrentIndex((prev) =>
        currentIndex === activeCollection.length - 1 ? prev : prev + 1
      );
    }
  }, [
    currentIndex,
    currentDocument,
    setActiveCollectionAndIndex,
    sortedCollection,
  ]);

  const prevDocument = useCallback(() => {
    const { move, prevCollection } = toPrevCollection(
      sortedCollection,
      currentDocument
    );
    if (move && prevCollection) {
      setActiveCollectionAndIndex(
        prevCollection[0].collectionUUID,
        prevCollection.length - 1
      );
    } else {
      setCurrentIndex((prev) => (currentIndex <= 0 ? 0 : prev - 1));
    }
  }, [
    currentIndex,
    currentDocument,
    setActiveCollectionAndIndex,
    sortedCollection,
  ]);

  const rotateCurrentImage = useCallback(
    async (degree: number) => {
      const imageBlob = currentDocument.file.startsWith('blob:')
        ? currentDocument.file
        : filesStore.getFile(currentDocument.file, false)?.blobUrl;
      if (imageBlob) {
        const rotated = (await getRotatedImagesFromUrl(imageBlob, degree))[0];
        setCurrentDocument({
          ...currentDocument,
          file: URL.createObjectURL(rotated),
        });

        documentsStore.editDocument({
          metadata: currentDocument.metadata,
          documentDate: currentDocument.documentDate,
          description: currentDocument.description,
          file: rotated,
        });
      }
    },
    [currentDocument, currentDocument.file, filesStore, documentsStore]
  );

  return {
    sortedCollection,
    sortType,
    handleSelectType,
    activeCollection,
    current: { index: currentIndex, document: currentDocument },
    prevDocument,
    nextDocument,
    setCurrentIndex,
    setActiveCollectionAndIndex,
    rotateCurrentImage,
  };
};

export default useCurrentDocument;
