import { types } from 'mobx-state-tree';

import { LoadingStatus } from './types';
import PatientsStore from './patients';
import DocumentsStore from './documents';
import ScheduleStore from './schedule';
import FilesStore from './files';

const RootStore = types.model('RootStore', {
  scheduleStore: types.optional(ScheduleStore, {
    getStudyRoomsLoadingStatus: LoadingStatus.NEVER,
    offerLoadingStatus: LoadingStatus.NEVER,
    orderLoadingStatus: LoadingStatus.NEVER,
  }),
  patientsStore: types.optional(PatientsStore, {
    loadingStatus: LoadingStatus.NEVER,
  }),
  documentsStore: types.optional(DocumentsStore, {
    loadingStatus: LoadingStatus.NEVER,
    editDocumentLoadingStatus: LoadingStatus.NEVER,
    createDocumentLoadingStatus: LoadingStatus.NEVER,
    deleteDocumentLoadingStatus: LoadingStatus.NEVER,
    documentToNewCollectionLoadingStatus: LoadingStatus.NEVER,
  }),
  filesStore: types.optional(FilesStore, { cashFiles: [] }),
});

export default RootStore;
