import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';

interface DeleteDocumentModalProps {
  isOpen: boolean;
  metadata: string;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteDocumentModal: FC<DeleteDocumentModalProps> = ({
  isOpen,
  metadata,
  onClose,
  onSuccess,
}) => {
  const { documentsStore } = useStore();

  const isDeleteDocumentLoading =
    documentsStore.deleteDocumentLoadingStatus === LoadingStatus.LOADING;

  useEffect(() => {
    if (documentsStore.deleteDocumentLoadingStatus === LoadingStatus.SUCCESS) {
      onSuccess();
      documentsStore.setDeleteDocumentLoadingStatus(LoadingStatus.NEVER);
    }
  }, [documentsStore.deleteDocumentLoadingStatus]);

  const handleDelete = () => {
    (async () => {
      if (documentsStore.patient?.patientID) {
        await documentsStore.deleteDocument(metadata);
      }
    })();
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={() => !isDeleteDocumentLoading && onClose()}
      footer={[
        <Button
          disabled={isDeleteDocumentLoading}
          key={'back'}
          onClick={() => onClose()}
        >
          Скасувати
        </Button>,
        <Button
          key={'submit'}
          type="primary"
          loading={isDeleteDocumentLoading}
          onClick={handleDelete}
        >
          Видалити
        </Button>,
      ]}
    >
      <ExclamationCircleOutlined /> Видалити документ?
    </Modal>
  );
};

export default observer(DeleteDocumentModal);
