import React, { CSSProperties, FC, forwardRef, Ref } from 'react';

import { Skeleton } from 'antd';

import styles from './AuthImage.module.scss';

import { useGetAuthFileUrl } from '../../hooks/useGetAuthFile';
import { LoadingStatus } from '../../store/types';

interface AuthFileProps {
  url: string;
  alt: string;
  compressed?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  ref?: Ref<HTMLImageElement>;
}

const AuthImage: FC<AuthFileProps> = forwardRef(
  ({ url, className, style, alt, onClick, compressed = false }, ref) => {
    const { fileUrl, loadingStatus } = useGetAuthFileUrl(url, compressed);

    if (loadingStatus === LoadingStatus.ERROR || !fileUrl) {
      return (
        <div className={styles.loadingImage}>
          <Skeleton.Image className={className} style={{ ...style }} />
        </div>
      );
    }

    return (
      <>
        {fileUrl && (
          <img
            ref={ref}
            src={fileUrl}
            className={className}
            style={style}
            alt={alt}
            onClick={onClick}
          />
        )}
      </>
    );
  }
);

export default AuthImage;
