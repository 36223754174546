import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import styles from './DocumentsList.module.scss';

import { IDocument } from '../../store/documents';
import AuthImage from '../AuthImage';
import useStore from '../../hooks/useStore';
import { useGetAuthFileUrl } from '../../hooks/useGetAuthFile';
import { isDocumentFilePdf } from '../../utils/commonHelpers';

interface DocumentListItemProps {
  document: IDocument;
}

const DocumentListItem: FC<DocumentListItemProps> = ({ document }) => {
  const isPdf = isDocumentFilePdf(document.filename);

  const { pathname } = useLocation();
  const history = useHistory();

  const { documentsStore } = useStore();
  const { fileUrl } = useGetAuthFileUrl(document.file, false, isPdf);

  const redirectToDocument = () => {
    documentsStore.setChosenDocument(document);
    history.push(`${pathname}/${document.filename}`);
  };

  const handleClick = () => {
    redirectToDocument();
  };

  return (
    <li key={document.filename} className={styles.item}>
      <div className={styles.meta}>
        <div>
          <b>Дата: </b> <span>{document.documentDate}</span>
        </div>
        <div>
          <b>Опис: </b> <span>{document.description}</span>
        </div>
      </div>
      {isPdf ? (
        <div>PDF</div>
      ) : (
        <AuthImage
          className={styles.image}
          url={fileUrl || ''}
          alt={'document'}
          onClick={handleClick}
        />
      )}
    </li>
  );
};

export default observer(DocumentListItem);
