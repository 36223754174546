import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { Alert, Button, Collapse, Input, notification } from 'antd';

import styles from '../Schedule.module.scss';

import { SearchData } from '../types';
import useStore from '../../../hooks/useStore';
import { LoadingStatus } from '../../../store/types';
import Time from './Time';
import CalendarDate from './CalendarDate';
import StudyRoom from './StudyRoom';
import Services from './Services';
import { dateFormat } from '../consts';
import PatientForOffer from './Patient/PatientForOffer';

const { Panel } = Collapse;

interface CreateNewOfferProps {
  onCreated: () => void;
}

const CreateNewOffer: FC<CreateNewOfferProps> = ({ onCreated }) => {
  const { scheduleStore } = useStore();

  const [searchData, setSearchData] = useState<SearchData>({
    studyRoomUUID: '',
    date: moment().format(dateFormat),
    time: '',
    patient: {
      patientID: null,
      name: '',
      email: '',
      phone: '',
      birthDate: '',
    },
    services: [],
    notes: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (scheduleStore.offerLoadingStatus === LoadingStatus.SUCCESS) {
      notification.success({
        message: 'Запис додано',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
      onCreated();
    } else if (scheduleStore.offerLoadingStatus === LoadingStatus.ERROR) {
      notification.error({
        message: 'Виникла помилка',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
    }
  }, [scheduleStore.offerLoadingStatus]);

  const handleCreate = () => {
    (async () => {
      if (
        !searchData.patient ||
        typeof searchData.patient !== 'object' ||
        (!searchData.patient.patientID &&
          (!searchData.patient.name ||
            !searchData.patient.email ||
            !searchData.patient.phone))
      ) {
        setError('Пацієнта не обрано');
      } else if (!searchData.services?.length) {
        setError('Послуги не обрані');
      } else if (!searchData.studyRoomUUID) {
        setError('Кабінет не обраний');
      } else if (!searchData.date) {
        setError('Дата не обрана');
      } else if (!searchData.time) {
        setError('Час не обрано');
      } else {
        setError('');
        await scheduleStore.offer(searchData);
      }
    })();
  };

  return (
    <div>
      <Collapse
        defaultActiveKey={[
          'patient',
          'time',
          'services',
          'notes',
          'studyRoom',
          'date',
        ]}
      >
        <Panel header="Обрати пацієнта" key="patient">
          <PatientForOffer
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </Panel>
        <Panel header="Дата" key="date">
          <CalendarDate
            setDate={(date) => setSearchData((prev) => ({ ...prev, date }))}
          />
        </Panel>
        <Panel header="Кабінет" key="studyRoom">
          <StudyRoom
            setStudyRoom={(studyRoomUUID: string) =>
              setSearchData((prev) => ({ ...prev, studyRoomUUID }))
            }
          />
        </Panel>
        <Panel header="Обрати обстеження" key="services">
          <Services searchData={searchData} setSearchData={setSearchData} />
        </Panel>
        <Panel header="Обрати час" key="time">
          <Time searchData={searchData} setSearchData={setSearchData} />
        </Panel>
        <Panel header="Примітка" key="notes">
          <Input
            placeholder="Примітка"
            onBlur={(e) =>
              setSearchData((prev) => ({ ...prev, notes: e.target.value }))
            }
          />
        </Panel>
      </Collapse>
      {!!error && (
        <Alert className={styles.errorMessage} message={error} type="error" />
      )}
      <Button
        style={{ margin: '20px 0' }}
        type="primary"
        loading={scheduleStore.offerLoadingStatus === LoadingStatus.LOADING}
        onClick={handleCreate}
      >
        Створити запис
      </Button>
    </div>
  );
};

export default observer(CreateNewOffer);
