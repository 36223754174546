import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader';

import { PatientsApi } from '../../api/patients';
import Loader from '../Loader';

const QRCodeCamera: FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleScan = (data: string | null) => {
    if (data) {
      (async () => {
        try {
          setLoading(true);
          await PatientsApi.getById(data);
          history.push(`/patients/${data}/documents`);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setError(`Пацієнта за ідентифікатором ${data} не знайдено`);
        }
      })();
    }
  };

  return (
    <div style={{marginTop: 20}}>
      <span style={{ color: '#ff0000', fontSize: 18 }}>{error}</span>
      {loading ? (
        <Loader />
      ) : (
        <QrReader
          style={{ width: '100%' }}
          delay={300}
          onError={() => {}}
          onScan={handleScan}
          facingMode={'environment'}
        />
      )}
    </div>
  );
};

export default QRCodeCamera;
