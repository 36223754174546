import { baseurl } from './baseurl';
import { IPatient } from '../store/patients';
import { CreatePatient } from '../pages/Schedule/types';
import makeRequest from './makeRequest';

export const PatientApi = {
  getById: async (id: string): Promise<IPatient> => {
    const { data } = await makeRequest<IPatient>({ url: baseurl + `/${id}` });
    return data;
  },
  create: async (payload: CreatePatient): Promise<{ patientID: string }> => {
    const { data } = await makeRequest<{ patientID: string }>({
      method: 'post',
      url: baseurl,
      data: payload,
    });
    return data;
  },
};
