import { FC, useState } from 'react';
import moment from 'moment';

import StudyRoom from './StudyRoom';
import CalendarDate from './CalendarDate';
import ScheduleTable from './ScheduleTable';
import { dateFormat } from '../consts';
import useScheduleTable from '../../../hooks/useScheduleTable';

const ScheduleJournal: FC = () => {
  const [studyRoom, setStudyRoom] = useState('');
  const [date, setDate] = useState(moment().format(dateFormat));

  const { scheduleItems, loading: scheduleItemsLoading } = useScheduleTable(
    studyRoom,
    date
  );

  return (
    <>
      <StudyRoom setStudyRoom={setStudyRoom} />
      <CalendarDate setDate={setDate} />
      <ScheduleTable
        scheduleItems={scheduleItems}
        loading={scheduleItemsLoading}
      />
    </>
  );
};

export default ScheduleJournal;
