import React, { FC } from 'react';

import { Button } from 'antd';
import {
  CloseSquareOutlined,
  FilePdfOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';

import styles from './CreateDocument.module.scss';

export interface IRotateImage {
  file: File;
  degree: number;
}

interface RotateImageProps {
  image: IRotateImage;
  onRotateLeft: (img: IRotateImage) => void;
  onRotateRight: (img: IRotateImage) => void;
  onDeleteImage?: (img: IRotateImage) => void;
}

const RotateImage: FC<RotateImageProps> = ({
  image,
  onDeleteImage,
  onRotateLeft,
  onRotateRight,
}) => {
  const isPdf = image.file.type.includes('pdf');

  return (
    <div className={styles.imageContainer} key={image.file.lastModified}>
      <div
        style={{
          transform: `rotate(${image.degree ? image.degree + 'deg' : 0})`,
        }}
        className={styles.imageWrap}
      >
        {isPdf ? (
          <div className={styles.pdf}>
            <span>PDF</span>
            <FilePdfOutlined />
          </div>
        ) : (
          <img
            className={styles.image}
            src={URL.createObjectURL(new Blob([image.file]))}
            alt=""
          />
        )}
      </div>
      {!isPdf && (
        <div className={styles.buttons}>
          <Button
            size="small"
            type="primary"
            icon={<RotateLeftOutlined />}
            onClick={() => onRotateLeft(image)}
          />
          <Button
            size="small"
            type="primary"
            icon={<RotateRightOutlined />}
            onClick={() => onRotateRight(image)}
          />
          {onDeleteImage && (
            <Button
              size="small"
              type="primary"
              icon={<CloseSquareOutlined />}
              onClick={() => onDeleteImage(image)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RotateImage;
