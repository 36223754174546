import React, { FC } from 'react';

import styles from './CreateDocument.module.scss';

import RotateImage, { IRotateImage } from './RotateImage';

interface ImagesProps {
  images: IRotateImage[];
  onRotateLeft: (img: IRotateImage) => void;
  onRotateRight: (img: IRotateImage) => void;
  onDeleteImage: (img: IRotateImage) => void;
}

const Images: FC<ImagesProps> = ({
  images,
  onDeleteImage,
  onRotateLeft,
  onRotateRight,
}) => {
  return (
    <div className={styles.images}>
      {images.map((image) => (
        <RotateImage
          key={image.file.lastModified}
          image={image}
          onRotateLeft={onRotateLeft}
          onRotateRight={onRotateRight}
          onDeleteImage={onDeleteImage}
        />
      ))}
    </div>
  );
};

export default Images;
