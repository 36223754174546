import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IService } from '../store/schedule';
import { ScheduleApi } from '../api/schedule';
import { clearAuthToken } from '../utils/tokenHelpers';
import { LoadingStatus } from '../store/types';

const useScheduleServices = (studyRoom: string) => {
  const history = useHistory();

  const [services, setServices] = useState<IService[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.NEVER);

  useEffect(() => {
    if (studyRoom) {
      (async () => {
        try {
          setLoading(LoadingStatus.LOADING);
          setServices(await ScheduleApi.getServices(studyRoom));
          setLoading(LoadingStatus.SUCCESS);
        } catch (e: any) {
          setLoading(LoadingStatus.ERROR);
        }
      })();
    }
  }, [studyRoom, history]);

  return {
    services,
    loading,
  };
};

export default useScheduleServices;
