import React, { FC } from 'react';

import styles from './DocumentDetails.module.scss';

import { IDocument } from '../../../store/documents';

interface DocumentDetailsProps {
  document: IDocument;
}

const DocumentDetails: FC<DocumentDetailsProps> = ({
  document: { creationDate, documentDate, documentType, description },
}) => {
  return (
    <div className={styles.documentDetails}>
      <div>
        <b>Дата створення: </b>
        <span>{creationDate || 'не вказано'}</span>
      </div>
      <div>
        <b>Вказана дата: </b>
        <span>{documentDate || 'не вказано'}</span>
      </div>
      <div>
        <b>Тип: </b>
        <span>{documentType || 'не вказано'}</span>
      </div>
      <div>
        <b>Опис: </b>
        <span>{description || 'не вказано'}</span>
      </div>
    </div>
  );
};

export default DocumentDetails;
