import React, { FC, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import 'moment/locale/uk';

import { Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

import { DocumentInputs, Nav } from '../../components';
import useStore from '../../hooks/useStore';
import { getRotatedImages } from '../../utils/canvasImageHelpers';
import { createPhoto } from '../../utils/cameraHelpers';
import { dateFormat } from '../Schedule/consts';
import Images from './Images';
import { IRotateImage } from './RotateImage';

moment.locale('uk');

const CreateDocument: FC = () => {
  const history = useHistory();
  const location = useLocation<{ files: FileList }>();
  const params = useParams<{ patientID: string }>();

  const [form] = useForm();

  const { documentsStore } = useStore();

  const [images, setImages] = useState<IRotateImage[]>(
    Array.from(location.state.files || []).map((file) => ({
      degree: 0,
      file: file,
    }))
  );

  const handleSave = async () => {
    if (!form) {
      return;
    }
    history.push(`/patients/${params.patientID}/documents`);
    const { date, desc, type } = form.getFieldsValue();

    try {
      const rotatedImages = await getRotatedImages(images);

      const collectionUUID = uuidv4();
      if (rotatedImages.length > 1) {
        await Promise.all(
          rotatedImages.map((image, index) =>
            documentsStore.createDocument(
              params.patientID,
              image,
              date ? moment(date).format(dateFormat) : '',
              type,
              desc,
              index,
              collectionUUID
            )
          )
        );
      } else {
        await documentsStore.createDocument(
          params.patientID,
          rotatedImages[0],
          date ? moment(date).format(dateFormat) : '',
          type,
          desc,
          0,
          collectionUUID
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCameraInputChange = (files: FileList | null) => {
    if (files) {
      setImages((prev) => [...prev, { degree: 0, file: files[0] }]);
    }
  };

  const handlePhotoClick = () => {
    createPhoto(handleCameraInputChange);
  };

  const handleRotateLeft = (file: { degree: number; file: File }) => {
    setImages((prev) =>
      prev.map((prevFile) => {
        if (prevFile.file.lastModified === file.file.lastModified) {
          return {
            ...prevFile,
            degree: prevFile.degree === 0 ? 270 : (prevFile.degree - 90) % 360,
          };
        }
        return prevFile;
      })
    );
  };

  const handleRotateRight = (file: { degree: number; file: File }) => {
    setImages((prev) =>
      prev.map((prevFile) => {
        if (prevFile.file.lastModified === file.file.lastModified) {
          return { ...prevFile, degree: (prevFile.degree + 90) % 360 };
        }
        return prevFile;
      })
    );
  };

  const handleDeleteImage = (file: { degree: number; file: File }) => {
    if (images.length === 1) {
      return history.push(`/patients/${params.patientID}/documents`);
    }
    setImages((prev) =>
      prev.filter(
        (prevFile) => prevFile.file.lastModified !== file.file.lastModified
      )
    );
  };

  const handleCancel = () => {
    history.push(`/patients/${params.patientID}/documents`);
  };

  if (!documentsStore.patient) {
    return <Redirect to={`/patients/${params.patientID}/documents`} />;
  }

  return (
    <>
      <Title level={3} className={'title'}>
        Створення документа
      </Title>
      <Nav
        afterButtons={[
          {
            title: 'Зберегти',
            icon: <CheckOutlined />,
            onClick: handleSave,
          },
          {
            title: 'Скасувати',
            icon: <CloseOutlined />,
            onClick: handleCancel,
          },
        ]}
      />
      <div style={{ fontSize: 16, paddingBottom: 55, position: 'relative' }}>
        <Images
          images={images}
          onRotateLeft={handleRotateLeft}
          onRotateRight={handleRotateRight}
          onDeleteImage={handleDeleteImage}
        />
        <Button onClick={handlePhotoClick}>Додати фото</Button>
        <div style={{ marginTop: 20 }}>
          <DocumentInputs form={form} />
        </div>
      </div>
    </>
  );
};

export default observer(CreateDocument);
