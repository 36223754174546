import { FC, useState } from 'react';

import { Button, Checkbox, Modal } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { TableColumn } from '../../pages/Schedule/types';

interface EditTableModalProps {
  visible: boolean;
  onOk: (checkedColumns: TableColumn[]) => void;
  onCancel: () => void;
  columnsState: {
    all: TableColumn[];
    checked: TableColumn[];
  };
}

const EditTableModal: FC<EditTableModalProps> = ({
  visible,
  onOk,
  onCancel,
  columnsState,
}) => {
  const [checkedColumns, setCheckedColumns] = useState<TableColumn[]>(
    columnsState.checked
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckChange = (list: CheckboxValueType[]) => {
    setCheckedColumns(
      columnsState.all.filter((c) => list.some((el) => el === c.title))
    );
    setIndeterminate(!!list.length && list.length < columnsState.all.length);
    setCheckAll(list.length === columnsState.all.length);
  };

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedColumns(e.target.checked ? columnsState.all : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Modal
      visible={visible}
      title="Оберіть колонки"
      onOk={() => onOk(checkedColumns)}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Скасувати
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk(checkedColumns)}
        >
          Зберегти
        </Button>,
      ]}
    >
      <Checkbox
        indeterminate={indeterminate}
        checked={checkAll}
        onChange={handleCheckAllChange}
      >
        Обрати всі
      </Checkbox>
      <Checkbox.Group
        options={columnsState.all.map((c) => ({
          label: c.title,
          value: c.title,
        }))}
        value={checkedColumns.map((c) => c.title)}
        onChange={handleCheckChange}
      />
    </Modal>
  );
};

export default EditTableModal;
