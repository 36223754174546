import { IDocument } from '../../store/documents';
import { SortedCollection } from './hooks/useCurrentDocument';

export const sortedCollectionToTwoDimensionalCollections = (
  collection: SortedCollection
): IDocument[][] => {
  return Object.keys(collection).reduce((sum, key) => {
    sum.push(...collection[key]);
    return sum;
  }, [] as IDocument[][]);
};

const getCollectionsMoveData = (
  sortedCollection: SortedCollection,
  currentDocument: IDocument
) => {
  const allCollections =
    sortedCollectionToTwoDimensionalCollections(sortedCollection);
  const currentCollectionIndex = allCollections.findIndex(
    (collection) =>
      collection[0].collectionUUID === currentDocument.collectionUUID
  );
  const prevCollection = allCollections[currentCollectionIndex - 1];
  const nextCollection = allCollections[currentCollectionIndex + 1];
  const currentCollection = allCollections[currentCollectionIndex];
  const currentIndex = currentCollection.findIndex(
    (el) => currentDocument.filename === el.filename
  );

  return {
    prevCollection,
    currentCollection,
    nextCollection,
    currentIndex,
  };
};

export const toPrevCollection = (
  sortedCollection: SortedCollection,
  currentDocument: IDocument
) => {
  const { currentCollection, currentIndex, prevCollection } =
    getCollectionsMoveData(sortedCollection, currentDocument);

  if (currentCollection && currentIndex >= 0 && prevCollection) {
    if (currentIndex === 0) {
      return { move: true, prevCollection };
    }
  }

  return { move: false, prevCollection: null };
};

export const toNextCollection = (
  sortedCollection: SortedCollection,
  currentDocument: IDocument
) => {
  const { currentCollection, currentIndex, nextCollection } =
    getCollectionsMoveData(sortedCollection, currentDocument);

  if (currentCollection && currentIndex >= 0 && nextCollection) {
    if (currentIndex === currentCollection.length - 1) {
      return { move: true, nextCollection };
    }
  }
  return { move: false, nextCollection: null };
};

export const getCollectionHeight = (
  collectionLength: number
): { height: number } => {
  return {
    height: Math.ceil(collectionLength / 6) * 50,
  };
};
