import { flow, Instance, types } from 'mobx-state-tree';

import { LoadingStatus } from './types';
import { ScheduleApi } from '../api/schedule';
import { SearchData } from '../pages/Schedule/types';

const StudyRoom = types.model('StudyRoom', {
  studyRoomUUID: types.string,
  name: types.string,
});

export interface IStudyRoom extends Instance<typeof StudyRoom> {}

const Service = types.model('Service', {
  serviceUUID: types.string,
  article: types.string,
  name: types.string,
  shortName: types.string,
  fullname: types.string,
  duration: types.number,
  price: types.number
})

export interface IService extends Instance<typeof Service> {}

const ScheduleItem = types.model('ScheduleItem', {
  time: types.string,
  status: types.string,
  patient: types.string,
  phone: types.string,
  visitNotes: types.string,
  accessionNumber: types.string,
  services: types.string,
  notes: types.string,
  creator: types.string,
  contragent: types.string,
  insurancePolicy: types.string,
  contragentNotes: types.string,
  referringOrganization: types.string,
  referringPhysician: types.string,
  reportCreator: types.string,
});

export interface IScheduleItem extends Instance<typeof ScheduleItem> {}

const ScheduleStore = types
  .model('ScheduleStore', {
    studyRooms: types.array(StudyRoom),
    orderError: types.optional(types.string, ''),
    getStudyRoomsLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    offerLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    orderLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setGetStudyRoomsLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getStudyRoomsLoadingStatus = loadingStatus;
    };

    const setUnauthorizedError = (isAuth: boolean) => {
      self.unauthorizedError = isAuth;
    };

    const setOfferLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.offerLoadingStatus = loadingStatus;
    };

    const setOrderLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.orderLoadingStatus = loadingStatus;
    };

    const setOrderError = (message: string) => {
      self.orderError = message
    }

    const getStudyRooms = flow(function* () {
      try {
        setGetStudyRoomsLoadingStatus(LoadingStatus.LOADING);
        self.studyRooms = yield ScheduleApi.getStudyRooms();
        setGetStudyRoomsLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e) {
        setGetStudyRoomsLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const offer = flow(function* (payload: SearchData) {
      try {
        setOfferLoadingStatus(LoadingStatus.LOADING);
        yield ScheduleApi.offer(payload);
        setOfferLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e) {
        setOfferLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const order = flow(function* (payload: SearchData) {
      try {
        setOrderError('')
        setOrderLoadingStatus(LoadingStatus.LOADING);
        yield ScheduleApi.order(payload);
        setOrderLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        setOrderError(e.message)
        setOrderLoadingStatus(LoadingStatus.ERROR);
      }
    });

    return {
      getStudyRooms,
      offer,
      order,
      setOfferLoadingStatus,
      setOrderLoadingStatus
    };
  });

export default ScheduleStore;
