import React, { FC, useState } from 'react';
import classNames from 'classnames';

import styles from './GalleryEditDocument.module.scss';

import GalleryDetails from '../GalleryDetails';
import { IDocument } from '../../../store/documents';
import DocumentMoveCollection from '../DocumentMoveCollection';
import { SortedCollection, SortType } from '../hooks/useCurrentDocument';

interface GalleryEditDocumentProps {
  sortedCollection: SortedCollection;
  sortType: SortType;
  setSortType: (type: SortType) => void;
  setPrevDocument: () => void;
  setNextDocument: () => void;
  currentDocument: IDocument;
  setActiveCollectionAndIndex: (
    collectionUUID: string | null,
    index: number
  ) => void;
}

const GalleryEditDocument: FC<GalleryEditDocumentProps> = ({
  sortedCollection,
  sortType,
  setSortType,
  currentDocument,
  setPrevDocument,
  setNextDocument,
  setActiveCollectionAndIndex,
}) => {
  const [edit, setEdit] = useState(false);
  const [moveCollections, setMoveCollections] = useState(true);

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerActive]: edit || moveCollections,
      })}
    >
      {!moveCollections && !edit && (
        <div className={styles.buttons}>
          <button className={styles.editButton} onClick={() => setEdit(true)}>
            <span>Редагувати</span>
          </button>
          <button
            className={styles.editButton}
            onClick={() => setMoveCollections(true)}
          >
            <span>Колекції</span>
          </button>
        </div>
      )}
      {(edit || moveCollections) && (
        <div className={styles.buttons}>
          <button
            className={styles.editButton}
            onClick={() => {
              setMoveCollections(false);
              setEdit(false);
            }}
          >
            <span>Сховати</span>
          </button>
        </div>
      )}
      <div className={styles.documentDetailMenu}>
        {edit && <GalleryDetails currentDocument={currentDocument} />}
        {moveCollections && (
          <DocumentMoveCollection
            sortType={sortType}
            setSortType={setSortType}
            sortedCollection={sortedCollection}
            currentDocument={currentDocument}
            setPrevDocument={setPrevDocument}
            setNextDocument={setNextDocument}
            setActiveCollectionAndIndex={setActiveCollectionAndIndex}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryEditDocument;
