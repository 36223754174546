import React, { FC } from 'react';
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';

import { Skeleton } from 'antd';

import styles from './PdfViewer.module.scss';

import { useGetAuthFileUrl } from '../../hooks/useGetAuthFile';
import { LoadingStatus } from '../../store/types';
import Loader from '../Loader';

interface PdfImageProps {
  url: string;
  onClick?: () => void;
  className?: string;
  onLoadSuccess?: (pdf: PDFDocumentProxy) => void;
  pageNumber?: number;
}

const PdfImage: FC<PdfImageProps> = ({
  url,
  onClick,
  className,
  onLoadSuccess,
  pageNumber,
}) => {
  const { fileUrl, loadingStatus } = useGetAuthFileUrl(url);

  if (loadingStatus === LoadingStatus.LOADING) {
    return (
      <div className={styles.loadingImage}>
        <Skeleton.Image className={className} />
        <Loader size={'large'} />
      </div>
    );
  }

  if (loadingStatus === LoadingStatus.ERROR || !fileUrl) {
    return (
      <div className={styles.loadingImage}>
        <Skeleton.Image className={className} />
      </div>
    );
  }

  return (
    <div className={styles.pdfImageWrap}>
      <Document file={fileUrl} onLoadSuccess={onLoadSuccess}>
        <Page
          onClick={onClick}
          pageNumber={pageNumber || 1}
          className={className}
        />
      </Document>
    </div>
  );
};

export default PdfImage;
