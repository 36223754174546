import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import Title from 'antd/lib/typography/Title';
import { Button, Empty, List, Typography } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  HourglassOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import styles from './UploadHistory.module.scss';

import useStore from '../../hooks/useStore';
import { AuthImage, Nav, PdfImage } from '../../components';
import { ICreateDocumentRequest } from '../../store/documents';

const UploadHistory: FC = () => {
  const { documentsStore } = useStore();

  const handleClearHistory = () => {
    documentsStore.clearRequestQueue();
  };

  const handleTryAgain = (document: ICreateDocumentRequest) => {
    (async () => {
      documentsStore.createDocument(
        document.patientID,
        document.file,
        document.documentDate,
        document.documentType,
        document.description,
        document.orderNumber,
        document.collectionUUID
      );
      documentsStore.deleteDocumentFromQueue([
        document.createDocumentRequestID,
      ]);
    })();
  };

  const handleTryAgainAll = () => {
    (async () => {
      const erroredRequests = documentsStore.createDocumentRequestQueue.filter(
        (d) => d.errorStatus
      );
      Promise.allSettled([
        erroredRequests.map((r) =>
          documentsStore.createDocument(
            r.patientID,
            r.file,
            r.documentDate,
            r.documentType,
            r.description,
            r.orderNumber,
            r.collectionUUID
          )
        ),
      ]);
      documentsStore.deleteDocumentFromQueue(
        erroredRequests.map((r) => r.createDocumentRequestID)
      );
    })();
  };

  return (
    <>
      <Title level={3} className="title">
        Історія завантажень
      </Title>
      <Nav
        afterButtons={[
          {
            title: 'Очистити',
            onClick: handleClearHistory,
          },
          {
            title: 'Повторити все',
            onClick: handleTryAgainAll,
          },
        ]}
      />
      {!!documentsStore.createDocumentRequestQueue.length ? (
        <List
          className={styles.list}
          itemLayout="horizontal"
          dataSource={toJS(documentsStore.createDocumentRequestQueue)
            .slice()
            .reverse()}
          renderItem={(document) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  document.file.type.split('/')[1] === 'pdf' ? (
                    <PdfImage
                      className={styles.pdf}
                      url={URL.createObjectURL(document.file)}
                    />
                  ) : (
                    <AuthImage
                      url={URL.createObjectURL(document.file)}
                      className={styles.image}
                      alt={'document'}
                    />
                  )
                }
                title={<Typography>Пацієнт: {document.patientName}</Typography>}
                description={
                  <>
                    <Typography>Опис: {document.description}</Typography>
                    <Typography>Дата: {document.documentDate}</Typography>
                    {!!document.errorMessage && (
                      <>
                        <Typography style={{ color: '#ff0000' }}>
                          Помилка: {document.errorMessage}
                        </Typography>
                        <Button
                          size={'small'}
                          onClick={() => handleTryAgain(document)}
                        >
                          Повторити
                        </Button>
                      </>
                    )}
                  </>
                }
              />
              <div>
                {document.networkWaiting ? (
                  <HourglassOutlined
                    className={styles.statusIcon}
                    style={{ color: '#ffa500' }}
                  />
                ) : document.resolved ? (
                  <CheckOutlined
                    className={styles.statusIcon}
                    style={{ color: '#008000' }}
                  />
                ) : document.errorStatus ? (
                  <CloseOutlined
                    className={styles.statusIcon}
                    style={{ color: '#ff0000' }}
                  />
                ) : (
                  <LoadingOutlined className={styles.statusIcon} />
                )}
              </div>
            </List.Item>
          )}
        />
      ) : (
        <Empty />
      )}
    </>
  );
};

export default observer(UploadHistory);
