import { useEffect, useState } from 'react';

import { SortType } from '../hooks/useCurrentDocument';

const useActiveKeys = (sortType: SortType, defaultKey: string) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  useEffect(() => {
    setActiveKeys((prev) => Array.from(new Set([...prev, defaultKey])));
  }, [defaultKey, sortType]);

  useEffect(() => {
    setActiveKeys([defaultKey]);
  }, [sortType]);

  return {
    activeKeys,
    setActiveKeys,
  };
};

export default useActiveKeys;
