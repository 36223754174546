import React, { FC, useState } from 'react';

import { Button, Tabs } from 'antd';

import styles from './Patient.module.scss';

import { StepProps } from '../../types';
import PatientForm from './PatientForm';
import PatientSelect from "./PatientSelect";
import { CloseOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

export interface SavedPatient {
  patientID: number;
  fullname: string;
  birthDate: string;
}

const Patient: FC<StepProps> = ({ setSearchData }) => {
  const [patient, setPatient] = useState<{
    patientID: number;
    fullname: string;
    birthDate: string;
  } | null>(null);

  const savePatient = (patient: SavedPatient) => {
    setPatient(patient);
    setSearchData((prev) => ({
      ...prev,
      patient: patient.patientID,
    }));
  };

  const handleClearUserClick = () => {
    setPatient(null);
    setSearchData((prev) => {
      if (typeof prev.patient === 'number') {
        delete prev.patient
      } else {
        prev.patient = {
          patientID: null,
          name: '',
          email: '',
          phone: '',
          birthDate: ''
        }
      }
      return prev
    });
  }

  return (
    <div>
      {patient && (
        <div className={styles.chosenPatient}>
          <Button type="primary" shape="circle" icon={<CloseOutlined />} onClick={handleClearUserClick} />
          <h4>Обраний пацієнт:</h4>
          <span>{patient.patientID}</span>
          <span>{patient.fullname}</span>
          <span>{patient?.birthDate}</span>
        </div>
      )}
      <Tabs
        size={'small'}
        defaultActiveKey="1"
      >
        <TabPane tab="Знайти" key="find">
           <PatientSelect chosenPatientID={patient?.patientID} onSelect={savePatient} />
        </TabPane>
        <TabPane tab="Створити пацієнта" key="create">
          <PatientForm onCreate={savePatient} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Patient;
