export enum LoadingStatus {
  NEVER = "NEVER",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum Filetype {
  PDF = "PDF",
  IMG = "IMG"
}