import { FC } from 'react';

import { Table } from 'antd';

import { IPatient } from '../../store/patients';

interface PatientsTableProps {
  isLoading: boolean;
  patients: IPatient[];
  handleRowClick: (patient: IPatient) => { onClick: () => void };
  rowClassName?: (patient: IPatient) => string;
}

const patientColumns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientID',
    key: 'patientID',
  },
  {
    title: "Пацієнт",
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Номер телефону',
    dataIndex: 'phones',
    key: 'phones',
  },
];

const PatientsTable:FC<PatientsTableProps> = ({isLoading,patients,handleRowClick, rowClassName}) => {
  return <Table
    style={{
      marginTop: 20,
      boxShadow: '0 2px 2px rgb(182 182 182 / 75%)',
    }}
    rowClassName={rowClassName}
    dataSource={patients.map((p) => ({
      ...p,
      key: p.patientID,
    }))}
    bordered
    loading={isLoading}
    columns={patientColumns}
    locale={{ emptyText: 'Введіть значення в полі пошуку' }}
    pagination={false}
    onRow={handleRowClick}
  />
}

export default PatientsTable
