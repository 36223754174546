import {
  SortedCollection,
  SortType,
} from '../components/DocumentsGallery/hooks/useCurrentDocument';
import { IDocument } from '../store/documents';
import { ddmmyyyyToDate } from './commonHelpers';

const orderCollectionKeysByDate = (
  collection: SortedCollection
): SortedCollection => {
  return Object.keys(collection)
    .sort(
      (key1, key2) =>
        ddmmyyyyToDate(key2).getTime() - ddmmyyyyToDate(key1).getTime()
    )
    .reduce((obj, key) => {
      obj[key] = collection[key];
      return obj;
    }, {} as SortedCollection);
};

const sortCollectionsByDate = (
  collections: IDocument[][]
): SortedCollection => {
  return collections.reduce((sum, current) => {
    if (sum[current[0].creationDate]) {
      sum[current[0].creationDate].push(current);
    } else {
      sum[current[0].creationDate] = [current];
    }
    return sum;
  }, {} as SortedCollection);
};

const orderCollectionListsByDate = (collection: SortedCollection) => {
  return Object.keys(collection)
    .map((key) => ({
      [key]: collection[key].sort(
        (d1, d2) =>
          ddmmyyyyToDate(d2[0].creationDate).getTime() -
          ddmmyyyyToDate(d1[0].creationDate).getTime()
      ),
    }))
    .reduce((sum, list) => {
      return { ...sum, ...list };
    }, {} as SortedCollection);
};

const sortCollectionsByType = (
  collections: IDocument[][]
): SortedCollection => {
  return collections.reduce((sum, current) => {
    if (sum[current[0].documentType || 'noType']) {
      sum[current[0].documentType || 'noType'].push(current);
    } else {
      sum[current[0].documentType || 'noType'] = [current];
    }
    return sum;
  }, {} as SortedCollection);
};

export const getSortedCollectionByDate = (
  collections: IDocument[][]
): SortedCollection => {
  return orderCollectionKeysByDate(sortCollectionsByDate(collections));
};

export const getSortedCollectionByType = (collections: IDocument[][]) => {
  return orderCollectionListsByDate(sortCollectionsByType(collections));
};

export const getSortedCollections = (
  collections: IDocument[][],
  sortType: SortType
): SortedCollection => {
  if (sortType === 'date') {
    return getSortedCollectionByDate(collections);
  } else if (sortType === 'type') {
    return getSortedCollectionByType(collections);
  } else return {};
};
