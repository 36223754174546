import React, { FC } from 'react';

import { Menu } from 'antd';
import {
  CameraOutlined,
  DownloadOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

import styles from './GalleryHeader.module.scss';

import Nav from '../../Nav';
import useStore from '../../../hooks/useStore';

interface GalleryHeaderProps {
  onBackClick: () => void;
  onPhotoClick: () => void;
  onUploadPhotosClick: () => void;
}

const GalleryHeader: FC<GalleryHeaderProps> = ({
  onBackClick,
  onPhotoClick,
  onUploadPhotosClick,
}) => {
  const { documentsStore } = useStore();

  return (
    <div className={styles.header}>
      <div style={{ flex: 1 }}>
        <Nav
          className={styles.detailsNavigation}
          beforeButtons={[
            {
              title: 'Назад',
              icon: <RollbackOutlined />,
              onClick: onBackClick,
            },
          ]}
          afterButtons={[
            {
              title: 'Фото',
              onClick: onPhotoClick,
              overlay: (
                <Menu>
                  <Menu.Item
                    key="1"
                    icon={<CameraOutlined />}
                    onClick={onPhotoClick}
                  >
                    Зробити фото
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={<DownloadOutlined />}
                    onClick={onUploadPhotosClick}
                  >
                    Завантажити фото
                  </Menu.Item>
                </Menu>
              ),
            },
          ]}
        />
      </div>
      <div className={styles.patientInfo}>
        <div>
          <b>ID: </b>
          <span>{documentsStore.patient?.patientID}</span>
        </div>
        <div>
          <b>Пацієнт: </b>
          <span>{documentsStore.patient?.fullName}</span>
        </div>
        <div>
          <b>День народження: </b>
          <span>{documentsStore.patient?.birthDate}</span>
        </div>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
};

export default GalleryHeader;
