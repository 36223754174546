import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IDocument } from '../../store/documents';
import GalleryItem from './GalleryItem';
import useCurrentDocument from './hooks/useCurrentDocument';
import GalleryNavigation from './GalleryNavigation';
import GalleryEditImage from './GalleryEditImage';
import GalleryHeader from './GalleryHeader';
import GalleryEditDocument from './GalleryEditDocument';
import DocumentDetails from './DocumentDetails';
import { isDocumentFilePdf } from '../../utils/commonHelpers';

interface DocumentGalleryProps {
  collections: IDocument[][];
  onPhotoClick: () => void;
  onUploadPhotosClick: () => void;
  onBackClick: () => void;
}

const DocumentGallery: FC<DocumentGalleryProps> = ({
  collections,
  onPhotoClick,
  onUploadPhotosClick,
  onBackClick,
}) => {
  const {
    sortedCollection,
    sortType,
    handleSelectType,
    activeCollection,
    current,
    nextDocument,
    prevDocument,
    setCurrentIndex,
    setActiveCollectionAndIndex,
    rotateCurrentImage,
  } = useCurrentDocument({ collections });

  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        nextDocument();
      } else if (e.key === 'ArrowUp') {
        prevDocument();
      } else if (e.key === '+' || e.key === 'ArrowRight') {
        setZoom((prev) => (prev + 15 >= 100 ? 100 : prev + 15));
      } else if (e.key === '-' || e.key === 'ArrowLeft') {
        setZoom((prev) => (prev - 15 <= 0 ? 0 : prev - 15));
      }
    };
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextDocument, prevDocument]);

  return (
    <div>
      <GalleryHeader
        onUploadPhotosClick={onUploadPhotosClick}
        onPhotoClick={onPhotoClick}
        onBackClick={onBackClick}
      />
      <GalleryNavigation
        setPrevDocument={prevDocument}
        setNextDocument={nextDocument}
        documents={activeCollection}
        currentIndex={current.index}
        setActiveDocument={setCurrentIndex}
      />
      <DocumentDetails document={current.document} />
      {current.document.filename &&
        !isDocumentFilePdf(current.document.filename) && (
          <GalleryEditImage
            rotateCurrentImage={rotateCurrentImage}
            zoom={zoom}
            setZoom={setZoom}
            currentDocument={current.document}
          />
        )}
      <GalleryEditDocument
        sortedCollection={sortedCollection}
        sortType={sortType}
        setSortType={handleSelectType}
        currentDocument={current.document}
        setPrevDocument={prevDocument}
        setNextDocument={nextDocument}
        setActiveCollectionAndIndex={setActiveCollectionAndIndex}
      />
      <GalleryItem
        setPrevDocument={prevDocument}
        setNextDocument={nextDocument}
        zoom={zoom}
        setZoom={setZoom}
        currentDocument={current.document}
      />
    </div>
  );
};

export default observer(DocumentGallery);
