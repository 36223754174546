import React, { FC } from 'react';
import moment from 'moment';
import 'moment/locale/uk';

import { DatePicker } from 'antd';

import { dateFormat } from '../consts';

moment.locale('uk');

interface CalendarDateProps {
  setDate: (date: string) => void;
  defaultDate?: string;
}

const CalendarDate: FC<CalendarDateProps> = ({ setDate, defaultDate }) => {
  return (
    <div>
      <DatePicker
        format={dateFormat}
        style={{ marginBottom: 20 }}
        onChange={(value) => value && setDate(value.format(dateFormat))}
        defaultValue={moment(defaultDate || undefined)}
        inputReadOnly
        allowClear={false}
      />
    </div>
  );
};

export default CalendarDate;
