import { FC, useState } from 'react';

import { Select } from 'antd';
import Search from 'antd/lib/input/Search';

interface PatientsSearchProps {
  onSearch: (value: string, selectType: string) => void;
  isLoading: boolean;
  setQr?: (isQr: boolean) => void;
  setJournal?: (isJournal: boolean) => void;
}

export enum SelectTypes {
  NAME = 'name',
  PHONE = 'phone',
  ID = 'id',
  QR = 'qr',
  JOURNAL = 'journal',
}

const { Option } = Select;

const defaultOptionValue = SelectTypes.JOURNAL;

const PatientsSearch: FC<PatientsSearchProps> = ({
  isLoading,
  onSearch,
  setQr,
  setJournal,
}) => {
  const [selectType, setSelectType] = useState<string>(setJournal ? defaultOptionValue : SelectTypes.ID);
  const [searchValue, setSearchValue] = useState('');

  const handleSelect = (value: string) => {
    setSearchValue('');
    if (value === SelectTypes.QR) {
      setJournal && setJournal(false);
      setQr && setQr(true);
    } else if (value === SelectTypes.JOURNAL) {
      setQr && setQr(false);
      setJournal && setJournal(true);
    } else {
      setQr && setQr(false);
      setJournal && setJournal(false);
    }
    setSelectType(value);
  };

  const handleSearch = (value: string) => {
    return onSearch(value, selectType);
  };

  return (
    <Search
      placeholder="Пошук пацієнта"
      onSearch={handleSearch}
      type={selectType === SelectTypes.NAME ? 'text' : 'number'}
      loading={isLoading}
      disabled={
        isLoading ||
        selectType === SelectTypes.QR ||
        selectType === SelectTypes.JOURNAL
      }
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      addonBefore={
        <Select
          style={{ width: 140, fontSize: 16 }}
          onChange={handleSelect}
          defaultValue={setJournal ? defaultOptionValue : SelectTypes.ID}
        >
          <Option style={{ fontSize: 16 }} value={SelectTypes.ID}>
            Patient ID
          </Option>
          {setQr && (
            <Option style={{ fontSize: 16 }} value={SelectTypes.QR}>
              QR
            </Option>
          )}
          <Option style={{ fontSize: 16 }} value={SelectTypes.NAME}>
            П.І.Б
          </Option>
          <Option style={{ fontSize: 16 }} value={SelectTypes.PHONE}>
            Телефон
          </Option>
          {setJournal && (
            <Option style={{ fontSize: 16 }} value={SelectTypes.JOURNAL}>
              Журнал
            </Option>
          )}
        </Select>
      }
    />
  );
};

export default PatientsSearch;
