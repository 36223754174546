import React, { FC } from 'react';

import { Table, Typography } from 'antd';

import { servicesColumns } from '../../consts';
import { IService } from '../../../../store/schedule';

interface ServicesTableProps {
  title: string;
  placeholder: string;
  services: IService[];
  onRow: (service: IService) => { onClick: () => void };
  rowClassName?: (row: IService) => string;
  loading?: boolean;
}

const ServicesTable: FC<ServicesTableProps> = ({
  title,
  services,
  loading,
  placeholder,
  onRow,
  rowClassName,
}) => {
  return (
    <>
      <Typography style={{ fontSize: 16, marginTop: 10 }}>{title}</Typography>
      <Table
        rowClassName={rowClassName}
        style={{ margin: '10px 5px 0 5px' }}
        columns={servicesColumns}
        scroll={{ y: 210 }}
        loading={loading}
        pagination={false}
        locale={{ emptyText: placeholder }}
        size="small"
        dataSource={services.map((s) => ({
          ...s,
          key: s.serviceUUID,
        }))}
        onRow={onRow}
      />
    </>
  );
};

export default ServicesTable;
