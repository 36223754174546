import { cast, flow, Instance, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { Patient } from './patients';
import { LoadingStatus } from './types';
import { DocumentsApi } from '../api/documents';
import { PatientApi } from '../api/patient';
import {
  collectionsToDocuments,
  getMetadataInfo,
  pause,
} from '../utils/commonHelpers';

const Document = types.model('Document', {
  creationDate: types.string,
  creationTime: types.string,
  documentDate: types.string,
  documentType: types.string,
  description: types.string,
  collectionUUID: types.string,
  filename: types.string,
  metadata: types.string,
  orderNumber: types.number,
  file: types.string,
});

export interface IDocument extends Instance<typeof Document> {}

export interface RearrangeDocumentPayload {
  editDocumentData: {
    metadata: string;
    newCollectionUUID: string;
    orderNumber: number;
    documentType: string;
    creationDate: string;
  };
  updatedCollection: IDocument[];
  sourceDocument: IDocument;
}

const CreateDocumentRequest = types
  .model('CreateDocumentRequest', {
    collectionUUID: types.string,
    createDocumentRequestID: types.string,
    patientID: types.string,
    patientName: types.string,
    documentDate: types.string,
    documentType: types.string,
    description: types.string,
    file: types.frozen(),
    percent: types.optional(types.number, 0),
    errorStatus: types.optional(types.string, ''),
    errorMessage: types.optional(types.string, ''),
    networkWaiting: types.optional(types.boolean, false),
    resolved: types.optional(types.boolean, false),
    orderNumber: types.number,
  })
  .actions((self) => {
    const setProgress = (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      self.percent = percentCompleted / 2;
    };
    return {
      setProgress,
    };
  });

export interface ICreateDocumentRequest
  extends Instance<typeof CreateDocumentRequest> {}

const DocumentsStore = types
  .model('DocumentsStore', {
    patient: types.maybeNull(Patient),
    collections: types.array(types.array(Document)),
    documentTypes: types.array(types.string),
    chosen: types.maybeNull(Document),
    createDocumentRequestQueue: types.array(CreateDocumentRequest),
    loadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    editDocumentLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    editGroupDocumentsStatuses: types.array(types.string),
    createDocumentLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    deleteDocumentLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    documentToNewCollectionLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setCreateDocumentLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.createDocumentLoadingStatus = loadingStatus;
    };

    const setEditDocumentLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.editDocumentLoadingStatus = loadingStatus;
    };

    const setDeleteDocumentLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.deleteDocumentLoadingStatus = loadingStatus;
    };

    const clearQueue = (queueElement: ICreateDocumentRequest) => {
      queueElement.networkWaiting = false;
      queueElement.resolved = true;
      queueElement.percent = 100;
    };

    const addDocument = (document: IDocument, file: File) => {
      let collectionExists = false;
      self.collections = cast(
        self.collections.map((documents) => {
          if (documents[0].collectionUUID === document.collectionUUID) {
            documents.push({
              ...document, // @ts-ignore
              url: URL.createObjectURL(file),
            });
            collectionExists = true;
            return documents;
          }
          return documents;
        })
      );

      if (!collectionExists) {
        self.collections.push([document]);
      }
    };

    const createDocument = flow(function* (
      patientID: string,
      file: File,
      documentDate: string = '',
      documentType: string = '',
      description: string = '',
      orderNumber: number = 0,
      collectionUUID: string,
      requestID?: string
    ) {
      const patientName = self.patient?.fullName || '';
      const queueElementID = requestID || uuidv4();
      if (!requestID) {
        self.createDocumentRequestQueue.push({
          collectionUUID,
          documentType,
          createDocumentRequestID: queueElementID,
          file,
          documentDate,
          description,
          patientName,
          patientID,
          orderNumber,
        });
      }
      const queueElement = self.createDocumentRequestQueue.find(
        (el) => el.createDocumentRequestID === queueElementID
      );
      if (!queueElement) return;
      try {
        const document = yield DocumentsApi.create(
          patientID,
          file,
          documentDate,
          documentType,
          orderNumber,
          description,
          collectionUUID,
          queueElement.setProgress
        );

        clearQueue(queueElement);
        addDocument(document, file);
      } catch (e: any) {
        if (e.message === "Файл з таким ім'ям вже існує") {
          clearQueue(queueElement);

          const metadata = `/patients/${patientID}/documents/metadata/${collectionUUID}/${file.name}`;
          const document = yield DocumentsApi.getById(metadata);

          addDocument(document, file);
        } else {
          if (e?.message === 'Network Error') {
            queueElement.networkWaiting = true;
            yield pause(20000);
            createDocument(
              patientID,
              file,
              documentDate,
              documentType,
              description,
              orderNumber,
              collectionUUID,
              queueElementID
            );
          } else {
            queueElement.errorStatus = String(
              e.message === 'Невідома помилка' ? '500' : '400'
            );
            queueElement.errorMessage = e.message;
            queueElement.networkWaiting = false;
          }
        }
      }
    });

    const getDocuments = flow(function* (patientID: string) {
      try {
        self.collections = yield DocumentsApi.getDocuments(patientID);
      } catch (e) {}
    });

    const getPatient = flow(function* (patientID: string) {
      try {
        self.loadingStatus = LoadingStatus.LOADING;
        const data = yield Promise.all([
          PatientApi.getById(patientID),
          DocumentsApi.getDocuments(patientID),
        ]);
        self.patient = data[0];

        self.collections = data[1];

        self.loadingStatus = LoadingStatus.SUCCESS;
      } catch (e) {
        self.loadingStatus = LoadingStatus.ERROR;
      }
    });

    const getDocumentById = flow(function* (metadata: string) {
      const { patientID } = getMetadataInfo(metadata);
      try {
        self.loadingStatus = LoadingStatus.LOADING;
        const data = yield Promise.all([
          PatientApi.getById(patientID),
          DocumentsApi.getById(metadata),
        ]);

        self.patient = data[0];
        self.chosen = data[1];
        self.loadingStatus = LoadingStatus.SUCCESS;
      } catch (e) {
        self.loadingStatus = LoadingStatus.ERROR;
      }
    });

    const setChosenDocument = (document: IDocument) => {
      self.chosen = { ...document };
    };

    const deleteCollectionDocument = (
      document: IDocument | { collectionUUID: string; filename: string }
    ) => {
      const sourceCollectionIndex = self.collections.findIndex(
        (collection) => collection[0].collectionUUID === document.collectionUUID
      );
      if (self.collections[sourceCollectionIndex].length === 1) {
        self.collections.splice(sourceCollectionIndex, 1);
      } else {
        if (self.collections[sourceCollectionIndex]) {
          const documentIndex = self.collections[
            sourceCollectionIndex
          ].findIndex((d) => d.filename === document.filename);
          if (documentIndex >= 0) {
            self.collections[sourceCollectionIndex].splice(documentIndex, 1);
          }
        }
      }
    };

    const getDocumentTypes = flow(function* () {
      try {
        const data = yield DocumentsApi.getDocumentTypes();
        self.documentTypes = data;
      } catch (e) {}
    });

    const deleteDocument = flow(function* (metadata: string) {
      const { collectionUUID, filename } = getMetadataInfo(metadata);
      try {
        self.deleteDocumentLoadingStatus = LoadingStatus.LOADING;
        yield DocumentsApi.delete(metadata);

        deleteCollectionDocument({ collectionUUID, filename });

        self.deleteDocumentLoadingStatus = LoadingStatus.SUCCESS;
      } catch (e) {
        self.deleteDocumentLoadingStatus = LoadingStatus.ERROR;
      }
    });

    const editDocument = flow(function* ({
      metadata,
      documentDate,
      documentType,
      description,
      file,
    }: {
      metadata: string;
      documentDate?: string;
      description?: string;
      documentType?: string;
      file?: File;
    }) {
      const { patientID, filename } = getMetadataInfo(metadata);
      try {
        self.editDocumentLoadingStatus = LoadingStatus.LOADING;

        self.editGroupDocumentsStatuses.push(filename);

        const data = yield DocumentsApi.edit({
          metadata,
          documentDate,
          description,
          documentType,
          file,
        });

        if (!collectionsToDocuments(self.collections).length) {
          yield getDocuments(patientID);
        }

        const sourceCollectionIndex = self.collections.findIndex(
          (collection) => collection[0].collectionUUID === data.collectionUUID
        );
        if (self.collections[sourceCollectionIndex]) {
          const documentIndex = self.collections[
            sourceCollectionIndex
          ].findIndex((d) => d.filename === data.filename);
          if (documentIndex >= 0) {
            self.collections[sourceCollectionIndex].splice(documentIndex, 1, {
              ...data,
              file: file
                ? URL.createObjectURL(file)
                : self.collections[sourceCollectionIndex][documentIndex].file,
            });
          }
        }

        self.editGroupDocumentsStatuses = cast(
          self.editGroupDocumentsStatuses.filter((id) => id !== filename)
        );
        self.editDocumentLoadingStatus = LoadingStatus.SUCCESS;
      } catch (e) {
        self.editGroupDocumentsStatuses = cast(
          self.editGroupDocumentsStatuses.filter((id) => id !== filename)
        );
        self.editDocumentLoadingStatus = LoadingStatus.ERROR;
      }
    });

    const rearrangeDocument = flow(function* ({
      editDocumentData,
      updatedCollection,
      sourceDocument,
    }: RearrangeDocumentPayload) {
      try {
        setEditDocumentLoadingStatus(LoadingStatus.LOADING);
        const targetCollectionIndex = self.collections.findIndex(
          (collections) =>
            collections[0].collectionUUID ===
            updatedCollection[0].collectionUUID
        );

        if (targetCollectionIndex < 0) return;

        self.collections[targetCollectionIndex] = cast(updatedCollection);
        if (
          updatedCollection[0].collectionUUID !== sourceDocument.collectionUUID
        ) {
          deleteCollectionDocument(sourceDocument);
        }
        yield DocumentsApi.edit(editDocumentData);
        setEditDocumentLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e) {
        yield getDocuments(editDocumentData.metadata.split('/')[2]);
        setEditDocumentLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const documentToNewCollection = flow(function* ({
      metadata,
      newCollectionUUID,
    }: {
      metadata: string;
      newCollectionUUID: string;
    }) {
      try {
        self.documentToNewCollectionLoadingStatus = LoadingStatus.LOADING;
        const newDocument = yield DocumentsApi.edit({
          metadata,
          newCollectionUUID,
          orderNumber: 0,
          documentType: '',
        });

        const collectionIndex = self.collections.findIndex(
          (collection) =>
            collection[0].collectionUUID === metadata.split('/')[5]
        );
        const documentIndex = self.collections[collectionIndex]?.findIndex(
          (doc) => doc.metadata === metadata
        );

        if (self.collections[collectionIndex].length === 1) {
          self.collections.splice(collectionIndex, 1);
        } else {
          self.collections[collectionIndex]?.splice(documentIndex, 1);
        }
        self.collections.push([newDocument]);
        self.documentToNewCollectionLoadingStatus = LoadingStatus.SUCCESS;
      } catch (e: any) {
        self.documentToNewCollectionLoadingStatus = LoadingStatus.ERROR;
      }
    });

    const deleteDocumentFromQueue = (documentIDs: string[]) => {
      self.createDocumentRequestQueue = cast(
        self.createDocumentRequestQueue.filter(
          (d) => !documentIDs.includes(d.createDocumentRequestID)
        )
      );
    };

    const clearRequestQueue = () => {
      self.createDocumentRequestQueue = cast(
        self.createDocumentRequestQueue.filter(
          (d) => !(d.resolved || !!d.errorStatus)
        )
      );
    };

    return {
      getPatient,
      getDocumentById,
      setChosenDocument,
      getDocumentTypes,
      createDocument,
      editDocument,
      rearrangeDocument,
      documentToNewCollection,
      deleteDocument,
      setCreateDocumentLoadingStatus,
      setEditDocumentLoadingStatus,
      setDeleteDocumentLoadingStatus,
      clearRequestQueue,
      deleteDocumentFromQueue,
    };
  });

export interface ICreateDocumentRequest
  extends Instance<typeof CreateDocumentRequest> {}

export default DocumentsStore;
