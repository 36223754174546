import { useEffect, useRef } from 'react';

interface UseGalleryScrollFlipProps {
  setPrevDocument: () => void;
  setNextDocument: () => void;
  work?: boolean;
}

const useGalleryScrollFlip = ({
  setPrevDocument,
  setNextDocument,
  work = true,
}: UseGalleryScrollFlipProps) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!work) return;
    const container = containerRef.current;
    if (container) {
      const handleScroll = (e: WheelEvent) => {
        if (e.shiftKey || e.altKey) {
          return;
        }

        e.preventDefault();

        if (e.deltaY > 0) {
          setNextDocument();
        } else if (e.deltaY < 0) {
          setPrevDocument();
        }
      };

      container.addEventListener('wheel', handleScroll);

      return () => {
        container.removeEventListener('wheel', handleScroll);
      };
    }
  }, [containerRef.current, setNextDocument, setPrevDocument, work]);

  return containerRef;
};

export default useGalleryScrollFlip;
