import { FC, useState, FocusEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { Input, Typography } from 'antd';

import styles from '../../Schedule.module.scss';

import { StepProps } from '../../types';
import { LoadingStatus } from '../../../../store/types';
import { IService } from '../../../../store/schedule';
import ServicesTable from './ServicesTable';
import useScheduleServices from '../../../../hooks/useScheduleServices';
import { getOffsetTop } from '../../../../utils/getOffsetTop';

const Services: FC<StepProps> = ({ searchData, setSearchData }) => {
  const { services, loading } = useScheduleServices(searchData.studyRoomUUID);

  const [serviceName, setServiceName] = useState<string>('');

  const addService = (service: IService) => ({
    onClick: () => {
      if (!searchData.services) {
        setSearchData((prev: any) => ({
          ...prev,
          services: [service],
        }));
        return;
      }

      if (
        !searchData.services.some((s) => s.serviceUUID === service.serviceUUID)
      ) {
        setSearchData((prev: any) => ({
          ...prev,
          services: [...(prev.services || []), service],
        }));
      }
    },
  });

  const removeService = (service: IService) => ({
    onClick: () => {
      setSearchData((prev: any) => ({
        ...prev,
        services: (prev.services || []).filter(
          (s: any) => s.serviceUUID !== service.serviceUUID
        ),
      }));
    },
  });

  const handleServiceNameInputFocus = (e: FocusEvent) => {
    const isMobileScreen = window.screen.width < 415;
    if (!isMobileScreen) {
      return;
    }

    const scrollHeight = getOffsetTop(e.target);
    const topMenuHeight = 50
    setTimeout(() => {
      document.body.scroll(0, scrollHeight - topMenuHeight);
    }, 1000)
  };

  const filteredServices = services.filter(
    (s) =>
      s.fullname.toLowerCase().includes(serviceName.toLowerCase()) ||
      s.article.toLowerCase().includes(serviceName.toLowerCase())
  );

  const chooseServicesData = serviceName ? filteredServices : [];

  return (
    <div>
      <Typography style={{ fontSize: 16 }}>
        Введіть коротку, або повну назву обстеження, або артикул
      </Typography>
      <Input
        style={{ width: '95%', marginTop: 10 }}
        placeholder="Назва обстеження"
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
        onFocus={handleServiceNameInputFocus}
      />
      <ServicesTable
        title="Оберіть обстеження зі списку"
        placeholder="Введіть значення в полі пошуку"
        services={chooseServicesData}
        loading={loading === LoadingStatus.LOADING}
        onRow={addService}
        rowClassName={(rowService) =>
          searchData.services?.some(
            (service) => service.serviceUUID === rowService.serviceUUID
          )
            ? styles.chosenRow
            : ''
        }
      />
      {!!searchData.services?.length && (
        <>
          <ServicesTable
            title="Обрані обстеження"
            placeholder="Додайте обстеження"
            services={searchData.services || []}
            loading={loading === LoadingStatus.LOADING}
            onRow={removeService}
          />
          <div style={{ textAlign: 'left', padding: 10 }}>
            <Typography>
              Кінцева вартість обстежень:{' '}
              <b>{searchData.services.reduce((s, c) => s + c.price, 0)} грн</b>
            </Typography>
            <Typography>
              Кінцева тривалість обстежень:{' '}
              <b>
                {searchData.services.reduce((s, c) => s + c.duration, 0)} хв
              </b>
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Services);
