import { IDocument } from '../store/documents';
import moment from 'moment';

export const urlToFile = async (
  dataUrl: string,
  fileName: string,
  mimeType: string
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: mimeType });
};

export const extractQueryParam = (url: string, param: string) => {
  return new URLSearchParams(url).get(param);
};

export const pause = async (timeout: number) => {
  await new Promise((res) => setTimeout(res, timeout));
};

export const ddmmyyyyToDate = (dateString: string): Date => {
  const dateParts = dateString.split('-');

  return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
};

export const collectionsToDocuments = (
  collections: IDocument[][]
): IDocument[] => {
  return collections.reduce((sum, current) => {
    return [...sum, ...current];
  }, [] as IDocument[]);
};

export const getMetadataInfo = (metadata: string) => {
  const metadataList = metadata.split('/');
  return {
    patientID: metadataList[2],
    collectionUUID: metadataList[5],
    filename: metadataList[6],
  };
};

export const createFullname = ({
  firstname,
  middlename,
  lastname,
}: {
  firstname: string;
  middlename: string;
  lastname: string;
}) => {
  return `${lastname} ${firstname} ${middlename}`;
};

// time: HH:MM
export const timeToDate = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return moment(new Date(0, 0, 0, hours, minutes)).toDate();
};

export const isDocumentFilePdf = (filename: string) => {
  return filename.substr(filename.length - 3) === 'pdf'
}
