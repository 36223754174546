import { useEffect, useState } from 'react';

import useStore from './useStore';
import { FilesApi } from '../api/files';
import { LoadingStatus } from '../store/types';

export const useGetAuthFileUrl = (
  url: string,
  compressed: boolean = false,
  isPdf = false
) => {
  const [fileUrl, setFileUrl] = useState<string>();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.NEVER
  );

  const { filesStore } = useStore();

  useEffect(() => {
    if (url) {
      setLoadingStatus(LoadingStatus.LOADING);
      if (isPdf && compressed) {
        setFileUrl('');
        setLoadingStatus(LoadingStatus.SUCCESS);
        return;
      }

      if (url.startsWith('blob:')) {
        setFileUrl(url);
        setLoadingStatus(LoadingStatus.SUCCESS);
        return;
      }

      (async () => {
        try {
          const cashedFile = filesStore.getFile(url, compressed);
          if (cashedFile) {
            setFileUrl(cashedFile.blobUrl);
            setLoadingStatus(LoadingStatus.SUCCESS);
            return;
          }
          const authFileUrl = await FilesApi.getAuthFileUrl(url, compressed);
          setFileUrl(authFileUrl);
          filesStore.addFile({
            serverUrl: url,
            blobUrl: authFileUrl,
            compressed,
          });
          setLoadingStatus(LoadingStatus.SUCCESS);
        } catch (e) {
          setLoadingStatus(LoadingStatus.ERROR);
        }
      })();
    } else {
      setFileUrl('');
    }
  }, [url, compressed]);

  return { fileUrl, loadingStatus };
};
