import { Spin } from 'antd';

import styles from './Loader.module.scss';
import { FC } from 'react';

interface LoaderProps {
  size?: 'large' | 'small' | 'default';
}

const Loader: FC<LoaderProps> = ({ size = 'large' }) => {
  return (
    <div className={styles.spin}>
      <Spin size={size} />
    </div>
  );
};

export default Loader;
