import { IDocument } from '../store/documents';
import { baseurl, homeurl } from './baseurl';
import makeRequest from './makeRequest';

export const DocumentsApi = {
  getDocuments: async (patientID: string): Promise<IDocument[][]> => {
    const { data } = await makeRequest<IDocument[][]>({
      url: baseurl + `/${patientID}/documents`,
    });

    return data;
  },

  getById: async (metadata: string) => {
    const { data } = await makeRequest<IDocument>({ url: homeurl + metadata });
    return data;
  },

  getDocumentTypes: async () => {
    const { data } = await makeRequest<string[]>({
      url: homeurl + '/patient-document-types',
    });

    return data;
  },

  create: async (
    patientID: string,
    image: File,
    documentDate: string,
    documentType: string,
    orderNumber: number,
    description: string,
    collectionUUID: string,
    handleUploadProgress: (progressEvent: ProgressEvent) => void
  ) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('documentDate', documentDate);
    formData.append('documentType', documentType);
    formData.append('description', description);
    formData.append('collectionUUID', collectionUUID);
    formData.append('orderNumber', String(orderNumber));

    const { data } = await makeRequest<IDocument>({
      method: 'post',
      url: baseurl + `/${patientID}/documents`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: handleUploadProgress,
    });

    return data;
  },

  edit: async ({
    metadata,
    documentDate,
    description,
    documentType,
    newCollectionUUID,
    orderNumber,
    file,
  }: {
    metadata: string;
    documentDate?: string;
    description?: string;
    documentType?: string;
    newCollectionUUID?: string;
    orderNumber?: number;
    file?: File;
  }) => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    if (newCollectionUUID) {
      formData.append('newCollectionUUID', newCollectionUUID);
    }
    if (documentType !== undefined) {
      formData.append('documentType', documentType);
    }
    if (orderNumber !== undefined) {
      formData.append('orderNumber', String(orderNumber));
    }
    if (documentDate !== undefined) {
      formData.append('documentDate', documentDate);
    }
    if (description !== undefined) {
      formData.append('description', description);
    }

    const { data } = await makeRequest<IDocument>({
      method: 'put',
      url: homeurl + metadata,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  },

  delete: async (metadata: string) => {
    await makeRequest({ method: 'delete', url: homeurl + metadata });
  },
};
