import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { lazily } from 'react-lazily';

import './App.scss';

import { LoginPage, Patient, PatientsList } from './pages';
import { clearAuthToken, isTokenExpired } from './utils/tokenHelpers';
import useStore from './hooks/useStore';
import { CreateDocumentProgress, SuspenseRoute } from './components';

const { Schedule, CreateDocument, Document, UploadHistory } = lazily(
  () => import('./pages')
);

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const { documentsStore, patientsStore, scheduleStore } = useStore();

  useEffect(() => {
    const querySearchParams = new URLSearchParams(location.search);
    const login = querySearchParams.get('login');
    const password = querySearchParams.get('password');

    if (login && password) {
      return;
    }

    (async () => {
      if (isTokenExpired()) {
        history.push('/login');
        clearAuthToken();
      }
    })();
  }, [history, location.search]);

  useEffect(() => {
    const unauthorized =
      documentsStore.unauthorizedError ||
      patientsStore.unauthorizedError ||
      scheduleStore.unauthorizedError;

    if (unauthorized) {
      history.push('/login');
    }
  }, [
    documentsStore.unauthorizedError,
    patientsStore.unauthorizedError,
    scheduleStore.unauthorizedError,
    history,
  ]);
  return (
    <Switch>
      <Route exact path={'/login'} component={LoginPage} />
      <Route path={'/'}>
        <CreateDocumentProgress />
        <Switch>
          <Route exact path={['/', '/patients']} component={PatientsList} />
          <SuspenseRoute exact path={'/schedule'} component={Schedule} />
          <Route
            exact
            path={'/patients/:patientID/documents'}
            component={Patient}
          />
          <SuspenseRoute
            exact
            path="/upload-history"
            component={UploadHistory}
          />
          <SuspenseRoute
            exact
            path="/patients/:patientID/documents/create"
            component={CreateDocument}
          />
          <SuspenseRoute
            exact
            path="/patients/:patientID/documents/:documentID"
            component={Document}
          />
        </Switch>
      </Route>
      <Route render={() => <div>Сторінку не знайдено</div>} />
    </Switch>
  );
};

export default observer(App);
