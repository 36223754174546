import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { isDesktop } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import PatientPhone from './PatientPhone';
import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';
import { Loader } from '../../components';
import { createPhoto, uploadPhotos } from '../../utils/cameraHelpers';
import DocumentsGallery from '../../components/DocumentsGallery';
import {collectionsToDocuments} from "../../utils/commonHelpers";

const Patient: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams<{ patientID: string }>();

  const { documentsStore, filesStore } = useStore();

  useEffect(() => {
    const anotherPatient =
      params.patientID !== documentsStore.patient?.patientID ||
      !collectionsToDocuments(documentsStore.collections).length;

    if (anotherPatient) {
      (async () => {
        await documentsStore.getPatient(params.patientID);
      })();
      filesStore.clearFiles();
    }
  }, [params.patientID, documentsStore]);

  if (documentsStore.loadingStatus === LoadingStatus.LOADING) {
    return <Loader />;
  }

  if (!documentsStore.patient) {
    return <div>Пацієнта не знайдено</div>;
  }

  const handleUploadPhotos = (files: FileList | null) => {
    if (files) {
      history.push({
        pathname: `${pathname}/create`,
        state: { files },
      });
    }
  };

  const handlePhotoClick = () => {
    createPhoto(handleUploadPhotos);
  };

  const handleUploadPhotosClick = () => {
    uploadPhotos(handleUploadPhotos);
  };

  const handleBackClick = () => {
    history.push(`/patients`);
  };

  return (
    <>
      {isDesktop ? (
        <DocumentsGallery
          collections={documentsStore.collections.slice(0)}
          onPhotoClick={handlePhotoClick}
          onUploadPhotosClick={handleUploadPhotosClick}
          onBackClick={handleBackClick}
        />
      ) : (
        <PatientPhone
          documents={collectionsToDocuments(documentsStore.collections).slice(0)}
          onPhotoClick={handlePhotoClick}
          onUploadPhotosClick={handleUploadPhotosClick}
          onBackClick={handleBackClick}
        />
      )}
    </>
  );
};

export default observer(Patient);
