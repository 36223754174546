import { baseurl } from './baseurl';
import { IPatient } from '../store/patients';
import makeRequest from './makeRequest';

export const PatientsApi = {
  getAll: async (): Promise<IPatient[]> => {
    const { data } = await makeRequest<IPatient[]>({ url: baseurl + '/' });
    return data;
  },

  getJournal: async (
    date: string,
    studyRoomID: string
  ): Promise<IPatient[]> => {
    const { data } = await makeRequest<IPatient[]>({
      url: baseurl + `?date=${date}&studyRoomUUID=${studyRoomID}`,
    });
    return data;
  },

  getByName: async (name: string): Promise<IPatient[]> => {
    const { data } = await makeRequest<IPatient[]>({
      url: baseurl + `?name=${name}`,
    });
    return data;
  },

  getByPhone: async (phone: string): Promise<IPatient[]> => {
    const { data } = await makeRequest<IPatient[]>({
      url: baseurl + `?phone=${phone}`,
    });
    return data;
  },

  getById: async (id: string): Promise<IPatient[]> => {
    const { data } = await makeRequest<IPatient[]>({
      url: baseurl + `?patientID=${id}`,
    });
    return data;
  },
};
