import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { Result } from 'antd';
import { CheckOutlined, RollbackOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { useForm } from 'antd/es/form/Form';

import { LoadingStatus } from '../../store/types';
import useStore from '../../hooks/useStore';
import { DocumentInputs, Loader, Nav, PatientInfo } from '../../components';
import RotateImage, { IRotateImage } from '../CreateDocument/RotateImage';
import { FilesApi } from '../../api/files';
import { dateFormat } from '../Schedule/consts';
import { getRotatedImages } from '../../utils/canvasImageHelpers';

const Document: FC = () => {
  const params = useParams<{
    patientID: string;
    collectionUUID: string;
    filename: string;
  }>();
  const history = useHistory();

  const { documentsStore } = useStore();

  useEffect(() => {
    const notChosen =
      !documentsStore.patient ||
      !documentsStore.chosen ||
      documentsStore.chosen.filename !== params.filename;

    if (notChosen) {
      (async () => {
        if (documentsStore.chosen) {
          await documentsStore.getDocumentById(documentsStore.chosen.metadata);
        }
      })();
    }
  }, [documentsStore.patient, params]);

  useEffect(() => {
    if (documentsStore.deleteDocumentLoadingStatus === LoadingStatus.SUCCESS) {
      history.push(`/patients/${params.patientID}/documents`);
      documentsStore.setDeleteDocumentLoadingStatus(LoadingStatus.NEVER);
    }
  }, [documentsStore.deleteDocumentLoadingStatus, history, params.patientID]);

  const [form] = useForm();
  const [file, setFile] = useState<IRotateImage | null>(null);

  useEffect(() => {
    (async () => {
      if (documentsStore.chosen) {
        const file = await FilesApi.getAuthFile(documentsStore.chosen.file);
        setFile({
          degree: 0,
          file,
        });
      }
    })();
  }, [documentsStore.chosen]);

  useEffect(() => {
    if (form) {
      form.setFields([
        {
          name: 'date',
          value: documentsStore.chosen?.documentDate
            ? moment(documentsStore.chosen.documentDate, 'DD/MM/YYYY')
            : null,
        },
        { name: 'desc', value: documentsStore.chosen?.description || '' },
      ]);
    }
  }, [form, documentsStore.chosen]);

  useEffect(() => {
    if (documentsStore.editDocumentLoadingStatus === LoadingStatus.ERROR) {
      documentsStore.setEditDocumentLoadingStatus(LoadingStatus.NEVER);
    }
  }, []);

  useEffect(() => {
    if (documentsStore.editDocumentLoadingStatus === LoadingStatus.SUCCESS) {
      history.push(`/patients/${params.patientID}/documents`);
      documentsStore.setEditDocumentLoadingStatus(LoadingStatus.NEVER);
    }
  }, [documentsStore.editDocumentLoadingStatus]);

  const handleSave = async () => {
    if (!documentsStore.chosen || !form) {
      return;
    }

    const { date, desc } = form.getFieldsValue();

    const isFileChanged = file && file?.degree !== 0;
    const isDocumentChanged =
      desc !== documentsStore.chosen!.description || date
        ? moment(date).format(dateFormat)
        : '' !== documentsStore.chosen!.documentDate;

    if (!isDocumentChanged && !isFileChanged) {
      return;
    }

    let image: File | undefined;

    if (isFileChanged) {
      const rotated = await getRotatedImages([file]);
      image = rotated[0];
    }

    await documentsStore.editDocument({
      metadata: documentsStore.chosen.metadata,
      documentDate: date ? moment(date).format(dateFormat) : '',
      description: desc,
      file: image,
    });
  };

  const handleRotateLeft = (file: { degree: number; file: File }) => {
    setFile({
      file: file.file,
      degree: file.degree === 0 ? 270 : (file.degree - 90) % 360,
    });
  };

  const handleRotateRight = (file: { degree: number; file: File }) => {
    setFile({ file: file.file, degree: (file.degree + 90) % 360 });
  };

  const handleBack = () => {
    history.push(`/patients/${documentsStore.patient!.patientID}/documents`);
  };

  if (documentsStore.loadingStatus === LoadingStatus.LOADING) {
    return <Loader />;
  }

  if (!documentsStore.patient) {
    return <div>Пацієнта не знайдено</div>;
  }

  if (!documentsStore.chosen) {
    return <div>Документ не знайдено</div>;
  }

  return (
    <>
      <Title level={3} className={'title'}>
        Редагування документа
      </Title>
      <Nav
        beforeButtons={[
          {
            title: 'Назад',
            icon: <RollbackOutlined />,
            onClick: handleBack,
          },
        ]}
        afterButtons={[
          {
            title: 'Зберегти',
            icon: <CheckOutlined />,
            onClick: handleSave,
            disabled:
              documentsStore.editDocumentLoadingStatus ===
              LoadingStatus.LOADING,
          },
        ]}
      />
      <div>
        <PatientInfo
          patient={{
            patientID: documentsStore.patient.patientID,
            fullName: documentsStore.patient.fullName,
            birthDate: documentsStore.patient.birthDate,
          }}
        />

        <div style={{ paddingBottom: 55 }}>
          {file && (
            <RotateImage
              image={file}
              onRotateLeft={handleRotateLeft}
              onRotateRight={handleRotateRight}
            />
          )}
          <DocumentInputs form={form} />
          {documentsStore.editDocumentLoadingStatus === LoadingStatus.ERROR && (
            <Result status="error" subTitle="Не вдалося редагувати документ" />
          )}
        </div>
      </div>
    </>
  );
};

export default observer(Document);
