import React, { FC, memo } from 'react';
import { Route } from 'react-router-dom';

import styles from './DocumentsList.module.scss';

import { IDocument } from '../../store/documents';
import DocumentListItem from './DocumentListItem';

interface DocumentsListProps {
  documents: IDocument[];
}

const DocumentsList: FC<DocumentsListProps> = ({ documents }) => {
  return (
    <>
      <ul className={styles.list}>
        {documents.map((document) => (
          <DocumentListItem key={document.filename} document={document} />
        ))}
      </ul>
    </>
  );
};

export default memo(DocumentsList);
