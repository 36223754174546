import React, { FC } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/uk';

import { TimePicker } from 'antd';

import { StepProps } from '../types';
import { timeFormat } from '../consts';

moment.locale('uk');

const Time: FC<StepProps> = ({ setSearchData }) => {
  const handleChange = (value: Moment | null) => {
    value &&
      setSearchData((prev) => ({
        ...prev,
        time: value.format(timeFormat),
      }));
  };

  return (
    <div>
      <TimePicker
        style={{ marginBottom: 20 }}
        onChange={handleChange}
        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]}
        hideDisabledOptions
        format={timeFormat}
        minuteStep={5}
        inputReadOnly
        showNow={false}
      />
    </div>
  );
};

export default Time;
