import { FC } from 'react';
import { GridDropZone } from 'react-grid-dnd';

import styles from './DndCollections.module.scss';

import { IDocument } from '../../../../store/documents';
import CollectionTitle from './CollectionTitle';
import { getCollectionHeight } from '../../utils';
import CollectionItem from '../CollectionItem';
import { SortType } from '../../hooks/useCurrentDocument';

interface DndCollectionsProps {
  collections: IDocument[][];
  currentDocument: IDocument;
  sortType: SortType;
  onMouseDown: () => void;
  onMouseUp: (document: IDocument) => void;
}

const DndCollections: FC<DndCollectionsProps> = ({
  collections,
  sortType,
  onMouseDown,
  onMouseUp,
  currentDocument,
}) => {
  return (
    <>
      {collections.map((collection) => (
        <ul key={collection[0].collectionUUID} className={styles.collection}>
          <li>
            <CollectionTitle sortType={sortType} collection={collection} />
            <GridDropZone
              style={getCollectionHeight(collection.length)}
              className={styles.collectionDropZone}
              id={collection[0].collectionUUID}
              boxesPerRow={6}
              rowHeight={50}
            >
              {collection.map((document) => (
                <CollectionItem
                  key={document.filename}
                  document={document}
                  currentDocument={currentDocument}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                />
              ))}
            </GridDropZone>
          </li>
        </ul>
      ))}
    </>
  );
};

export default DndCollections;
