import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Alert, Table } from 'antd';

import { dateFormat } from '../../pages/Schedule/consts';
import { IPatient } from '../../store/patients';
import { CalendarDate, StudyRoom } from '../../pages/Schedule/components';
import { PatientsApi } from '../../api/patients';
import { LoadingStatus } from '../../store/types';

const patientJournalColumns = [
  {
    title: 'Час',
    dataIndex: 'studyTime',
    key: 'studyTime',
    sorter: (a:any, b: any) => {
      // @ts-ignore
      return new Date().setHours(...a.studyTime.split(':')) - new Date().setHours(...b.studyTime.split(':'))
    },
    sortDirections: ['descend'],
  },
  {
    title: 'Patient ID',
    dataIndex: 'patientID',
    key: 'patientID',
    sorter: (a:any, b:any) => +a.patientID - +b.patientID,
  },
  {
    title: "Пацієнт",
    dataIndex: 'fullName',
    key: 'fullName',
    sorter: (a:any, b:any) => {
      if(a.fullName < b.fullName) { return -1; }
      if(a.fullName > b.fullName) { return 1; }
      return 0;
    },
  },
  {
    title: 'Номер телефону',
    dataIndex: 'phones',
    key: 'phones',
  },
]

const PatientsJournal = () => {
  const history = useHistory();

  const [studyRoom, setStudyRoom] = useState('');
  const [date, setDate] = useState(moment().format(dateFormat));
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.NEVER);

  useEffect(() => {
    if (studyRoom && date) {
      (async () => {
        try {
          setLoading(LoadingStatus.LOADING);
          const patients = await PatientsApi.getJournal(date, studyRoom);
          setPatients(patients);
          setLoading(LoadingStatus.SUCCESS);
        } catch (e: any) {
          setLoading(LoadingStatus.ERROR);
          setPatients([]);
        }
      })();
    }
  }, [studyRoom, date]);

  const handlePatientClick = (patient: IPatient) => ({
    onClick: () => history.push(`/patients/${patient.patientID}/documents`),
  });

  return (
    <div style={{ marginTop: 20 }}>
      <StudyRoom setStudyRoom={setStudyRoom} />
      <CalendarDate setDate={setDate} />
      {loading === LoadingStatus.ERROR && (
        <Alert type={'error'} message={'Виникла помилка'} />
      )}
      <Table
        style={{
          marginTop: 20,
          boxShadow: '0 2px 2px rgb(182 182 182 / 75%)',
        }}
        dataSource={patients.map((p) => ({
          ...p,
          key: p.patientID,
        }))}
        bordered
        loading={loading === LoadingStatus.LOADING}// @ts-ignore
        columns={patientJournalColumns}
        locale={{ emptyText: 'Записи відсутні' }}
        pagination={false}
        scroll={{ x: true }}
        onRow={handlePatientClick}
      />
    </div>
  );
};

export default PatientsJournal;
