import React, { FC, useEffect, useRef } from 'react';
import { GridItem } from 'react-grid-dnd';
import classNames from 'classnames';

import styles from './CollectionItem.module.scss';

import AuthImage from '../../../AuthImage';
import { IDocument } from '../../../../store/documents';
import { isDocumentFilePdf } from '../../../../utils/commonHelpers';
import { FilePdfOutlined } from '@ant-design/icons';

interface CollectionItemProps {
  document: IDocument;
  currentDocument: IDocument;
  onMouseDown: () => void;
  onMouseUp: (document: IDocument) => void;
  draggable?: boolean;
}

const CollectionItem: FC<CollectionItemProps> = ({
  draggable = true,
  document,
  currentDocument,
  onMouseDown,
  onMouseUp,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button && currentDocument.filename === document.filename) {
      button.focus();
    }
  }, [buttonRef.current, currentDocument]);

  const handleMouseUp = () => {
    onMouseUp(document);
  };

  if (!draggable) {
    return (
      <div
        className={styles.undraggableImage}
        key={document.filename}
        onMouseDown={onMouseDown}
        onMouseUp={handleMouseUp}
      >
        {isDocumentFilePdf(document.filename) ? (
          <div className={classNames(styles.pdf, {
            [styles.activeImage]:
            document.filename === currentDocument.filename,
          })}>
            <FilePdfOutlined />
          </div>
        ) : (
          <AuthImage
            className={classNames(styles.image, {
              [styles.activeImage]:
                document.filename === currentDocument.filename,
            })}
            url={document.file}
            compressed
            alt=""
          />
        )}
      </div>
    );
  }

  return (
    <div onMouseDown={onMouseDown} onMouseUp={handleMouseUp}>
      <GridItem className={styles.draggable}>
        <button ref={buttonRef}>
          {isDocumentFilePdf(document.filename) ? (
            <div className={classNames(styles.pdf, {
              [styles.activeImage]:
              document.filename === currentDocument.filename,
            })}>
              <FilePdfOutlined />
            </div>
          ) : (
            <AuthImage
              className={classNames(styles.image, {
                [styles.activeImage]:
                  document.filename === currentDocument.filename,
              })}
              url={document.file}
              compressed
              alt=""
            />
          )}
        </button>
      </GridItem>
    </div>
  );
};

export default CollectionItem;
