import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { Button, Result } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';

import styles from './GalleryDetails.module.scss';

import DocumentInputs from '../../DocumentInputs';
import { dateFormat } from '../../../pages/Schedule/consts';
import useStore from '../../../hooks/useStore';
import { IDocument } from '../../../store/documents';
import { LoadingStatus } from '../../../store/types';
import DeleteDocumentModal from '../../DeleteDocumentModal';
import { collectionsToDocuments } from '../../../utils/commonHelpers';

interface GalleryDetailsProps {
  currentDocument: IDocument;
}

const GalleryDetails: FC<GalleryDetailsProps> = ({ currentDocument }) => {
  const { documentsStore } = useStore();

  const [form] = useForm();

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (form) {
      form.setFields([
        {
          name: 'type',
          value: currentDocument.documentType,
        },
        {
          name: 'date',
          value: currentDocument.documentDate
            ? moment(currentDocument.documentDate, 'DD/MM/YYYY')
            : null,
        },
        { name: 'desc', value: currentDocument.description || '' },
      ]);
    }
  }, [form, currentDocument]);

  useEffect(() => {
    if (documentsStore.editDocumentLoadingStatus === LoadingStatus.ERROR) {
      documentsStore.setEditDocumentLoadingStatus(LoadingStatus.NEVER);
    }
  }, []);

  const handleSave = async () => {
    if (!form || !documentsStore.patient) {
      return;
    }

    const { date, desc, type } = form.getFieldsValue();

    const isDocumentChanged =
      desc !== currentDocument.description ||
      (date ? moment(date).format(dateFormat) : '') !==
        currentDocument.documentDate ||
      type !== currentDocument.documentType;

    if (!isDocumentChanged) {
      return;
    }

    await documentsStore.editDocument({
      metadata: currentDocument.metadata,
      documentDate: date ? moment(date).format(dateFormat) : '',
      description: desc,
      documentType: type,
    });
  };

  const editDocumentLoading = documentsStore.editGroupDocumentsStatuses.some(
    (id) => id === currentDocument.filename
  );

  return (
    <div className={styles.details}>
      {!!(
        currentDocument &&
        collectionsToDocuments(documentsStore.collections).length
      ) && (
        <>
          <Button
            className={styles.deleteButton}
            disabled={
              documentsStore.deleteDocumentLoadingStatus ===
              LoadingStatus.LOADING
            }
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => setDeleteModal(true)}
          />
          <div style={{ padding: 10 }}>
            <DocumentInputs form={form} />
            <Button
              style={{ marginTop: 10 }}
              type="primary"
              loading={editDocumentLoading}
              disabled={editDocumentLoading}
              onClick={handleSave}
            >
              Зберегти
            </Button>
          </div>
        </>
      )}
      {documentsStore.editDocumentLoadingStatus === LoadingStatus.ERROR && (
        <Result status="error" subTitle="Не вдалося редагувати документ" />
      )}
      <DeleteDocumentModal
        isOpen={deleteModal}
        metadata={currentDocument.metadata}
        onClose={() => setDeleteModal(false)}
        onSuccess={() => setDeleteModal(false)}
      />
    </div>
  );
};

export default observer(GalleryDetails);
