import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { ConfigProvider } from 'antd';
import uk_UA from 'antd/lib/locale/uk_UA';

import 'antd/dist/antd.css';

import App from './App';
import RootStore from './store';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const store = RootStore.create({});

export const StoreContext = createContext(store);

console.log(
  process.env.REACT_APP_API_NAME,
  'version',
  process.env.REACT_APP_GIT_SHA
);

ReactDOM.render(
  <Router>
    <StoreContext.Provider value={store}>
      <ConfigProvider locale={uk_UA}>
        <App />
      </ConfigProvider>
    </StoreContext.Provider>
  </Router>,
  document.getElementById('root')
);
