export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'HH:mm';

export const scheduleTableColumns = [
  {
    title: 'Час',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Пацієнт',
    dataIndex: 'patient',
    key: 'patient',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Примітка візиту',
    dataIndex: 'visitNotes',
    key: 'visitNotes',
  },
  {
    title: 'Accession number',
    dataIndex: 'accessionNumber',
    key: 'accessionNumber',
  },
  {
    title: 'Послуги',
    dataIndex: 'services',
    key: 'services',
  },
  {
    title: 'Примітка',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: 'Записав',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: 'Контрагент',
    dataIndex: 'contragent',
    key: 'contragent',
  },
  {
    title: '№ полісу',
    dataIndex: 'insurancePolicy',
    key: 'insurancePolicy',
  },
  {
    title: 'Примітка контрагенту',
    dataIndex: 'contragentNotes',
    key: 'contragentNotes',
  },
  {
    title: 'Направляюча організація',
    dataIndex: 'referringOrganization',
    key: 'referringOrganization',
  },
  {
    title: 'Направляючий співробітник',
    dataIndex: 'referringPhysician',
    key: 'referringPhysician',
  },
  {
    title: 'Лікар, який написав протокол',
    dataIndex: 'reportCreator',
    key: 'reportCreator',
  },
];

export const servicesColumns = [
  {
    title: 'Назва',
    dataIndex: 'fullname',
    key: 'fullname',
  },
  {
    title: 'Ціна, грн',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Тривалість, хв',
    dataIndex: 'duration',
    key: 'duration',
  },
];
