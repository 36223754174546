import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';

import { Nav } from '../../components';
import { CreateNewOffer, CreateNewOrder, ScheduleJournal } from './components';

const { TabPane } = Tabs;

const Schedule: FC = () => {
  const [activeTab, setActiveTab] = useState('journal');

  const handleNewRecordCreated = () => {
    setActiveTab('journal');
    document.body.scroll({ top: 0 });
  };

  return (
    <div>
      <Title level={3} className="title">
        Журнал запису
      </Title>
      <Nav />
      <Tabs destroyInactiveTabPane centered activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Журнал" key="journal">
          <ScheduleJournal />
        </TabPane>
        <TabPane tab="Створити запис" key="order">
          <CreateNewOrder onCreated={handleNewRecordCreated} />
        </TabPane>
        <TabPane tab="Попередній запис" key="offer">
          <CreateNewOffer onCreated={handleNewRecordCreated} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default observer(Schedule);
