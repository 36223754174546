import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Radio } from 'antd';

import useStore from '../../../hooks/useStore';
import { LoadingStatus } from '../../../store/types';
import { Loader } from '../../../components';

interface StudyRoomProps {
  setStudyRoom: (studyRoom: string) => void,
  defaultRoom?: string
}

const StudyRoom: FC<StudyRoomProps> = ({  setStudyRoom, defaultRoom }) => {
  const { scheduleStore } = useStore();

  useEffect(() => {
    if (!scheduleStore.studyRooms.length) {
      (async () => {
        await scheduleStore.getStudyRooms();
      })();
    }
  }, [scheduleStore.studyRooms.length]);

  useEffect(() => {
    if (scheduleStore.studyRooms.length) {
      setStudyRoom(defaultRoom || scheduleStore.studyRooms[0].studyRoomUUID)
    }
  }, [scheduleStore.studyRooms.length, defaultRoom]);

  return (
    <div>
      {scheduleStore.getStudyRoomsLoadingStatus !== LoadingStatus.LOADING ? (
        <>
          {!!scheduleStore.studyRooms.length && (
            <Radio.Group
              defaultValue={defaultRoom || scheduleStore.studyRooms[0].studyRoomUUID}
              buttonStyle="solid"
              style={{ marginBottom: 20 }}
            >
              {scheduleStore.studyRooms.map((room) => (
                <Radio.Button
                  key={room.studyRoomUUID}
                  value={room.studyRoomUUID}
                  onClick={() => {
                    setStudyRoom(room.studyRoomUUID);
                  }}
                >
                  {room.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
        </>
      ) : (
        <div style={{ height: 40, width: 50, position: 'relative' }}>
          <Loader size="default" />
        </div>
      )}
    </div>
  );
};
export default observer(StudyRoom);
