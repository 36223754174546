import { homeurl } from './baseurl';
import { SearchData } from '../pages/Schedule/types';
import { IScheduleItem, IService, IStudyRoom } from '../store/schedule';
import makeRequest from './makeRequest';

export const ScheduleApi = {
  getStudyRooms: async () => {
    const { data } = await makeRequest<IStudyRoom>({
      url: homeurl + '/study-rooms',
    });
    return data;
  },
  getServices: async (studyRoomUUID: string) => {
    const { data } = await makeRequest<IService[]>({
      url: homeurl + `/${studyRoomUUID}/services`,
    });
    return data;
  },
  getSchedule: async (studyRoomUUID: string, date: string) => {
    const { data } = await makeRequest<IScheduleItem[]>({
      url: homeurl + `/schedule/${studyRoomUUID}/${date}`,
    });
    return data;
  },
  offer: async (payload: SearchData) => {
    await makeRequest({
      method: 'post',
      url: homeurl + `/schedule/offer`,
      data: {
        studyRoomUUID: payload.studyRoomUUID,
        date: payload.date,
        time: payload.time,
        services: payload.services!.map((s) => ({
          serviceUUID: s.serviceUUID,
        })),
        patient: payload.patient,
        notes: payload.notes,
      },
    });
  },
  order: async (payload: SearchData) => {
    const data = payload.services?.length
      ? {
          ...payload,
          services: payload.services.map((s) => ({
            serviceUUID: s.serviceUUID,
          })),
        }
      : payload;
    const orderData = data.patient
      ? { ...data, patientID: data.patient }
      : data;
    delete orderData.patient;
    await makeRequest({
      method: 'post',
      url: homeurl + `/schedule/order`,
      data: orderData,
    });
  },
};
