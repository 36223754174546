import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IScheduleItem } from '../store/schedule';
import { ScheduleApi } from '../api/schedule';
import { clearAuthToken } from '../utils/tokenHelpers';
import { LoadingStatus } from '../store/types';

const useScheduleTable = (studyRoom: string, date: string) => {
  const history = useHistory();

  const [scheduleItems, setScheduleItems] = useState<IScheduleItem[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.NEVER);
  const [isPoll, setIsPoll] = useState(true);

  useEffect(() => {
    if (studyRoom && date) {
      (async () => {
        try {
          setLoading(LoadingStatus.LOADING);
          setScheduleItems(await ScheduleApi.getSchedule(studyRoom, date));
          setLoading(LoadingStatus.SUCCESS);
        } catch (e: any) {
          setLoading(LoadingStatus.ERROR);
        }
      })();
    }
  }, [studyRoom, date, history]);

  useEffect(() => {
    let poll: null | (() => Promise<void>);
    let setSchedule: null | (() => Promise<void>);
    let timeout: ReturnType<typeof setTimeout>;
    if (studyRoom) {
      poll = async () => {
        await new Promise((res) => {
          timeout = setTimeout(() => res(null), 5000);
        });

        await setSchedule?.();
      };

      setSchedule = async () => {
        try {
          if (studyRoom && isPoll) {
            setScheduleItems(await ScheduleApi.getSchedule(studyRoom, date));
          }
        } catch (e) {
        } finally {
          poll?.();
        }
      };

      poll();
    }
    return () => {
      poll = null;
      setSchedule = null;
      clearTimeout(timeout);
    };
  }, [studyRoom, date, history, isPoll]);

  return {
    scheduleItems,
    loading,
    setIsPoll,
  };
};

export default useScheduleTable;
