import Compressor from 'compressorjs';
import { v4 as uuidv4 } from 'uuid';
import { IRotateImage } from '../pages/CreateDocument/RotateImage';

export const initializeCanvasImage = (
  canvas: HTMLCanvasElement,
  imageUrl: string
) => {
  const ctx = canvas?.getContext('2d');
  if (canvas && ctx && imageUrl) {
    const image = document.createElement('img');
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(image.width / 2, image.height / 2);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);
    };
    image.src = imageUrl;
  }
};

export const rotateImage = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  degree: number
) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  if (degree === 90 || degree === 270) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (degree === 90 || degree === 270) {
    ctx.translate(image.height / 2, image.width / 2);
  } else {
    ctx.translate(image.width / 2, image.height / 2);
  }
  ctx.rotate((degree * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
};

export const getRotatedUrlImage = async (
  canvas: HTMLCanvasElement,
  fileUrl: string,
  degree: number
): Promise<File> => {
  const image = document.createElement('img');
  image.src = fileUrl;
  const blob = await new Promise((resolve) => {
    image.onload = async () => {
      rotateImage(canvas, image, degree);
      canvas.toBlob(resolve, 'jpeg');
    };
  });
  return new File([blob as Blob], uuidv4() + '.jpeg', { type: 'jpeg' });
};

export const getRotatedImagesFromUrl = async (
  imageUrl: string,
  degree: number
): Promise<File[]> => {
  const res = await fetch(imageUrl);
  const blob = await res.blob();
  const file = new File(
    [blob as Blob],
    `${uuidv4()}.${blob.type.split('/')[1]}`,
    { type: blob.type }
  );
  return getRotatedImages([{ file, degree }]);
};

export const getRotatedImages = async (
  files: IRotateImage[]
): Promise<File[]> => {
  const canvas = document.createElement('canvas');
  const images: Promise<File>[] = files.map(async (file) => {

    // @ts-ignore
    const extension = file.file.name.split('.').at(-1);
    const filename = `${uuidv4()}.${extension.toLowerCase()}`

    if (file.file.type.includes('pdf')) {
      return new File([file.file], filename, {
        type: file.file.type.toLowerCase(),
      });
    }
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'anonymous';
    const imageSrc = URL.createObjectURL(file.file);

    if (!imageSrc || !ctx) {
      throw new Error('Error wile converting');
    }

    image.src = imageSrc;
    const degrees = file.degree;
    const fileBlob:File | Blob = degrees ? await new Promise((resolve) => {
      image.onload = async () => {
        rotateImage(canvas, image, degrees);
        // @ts-ignore
        canvas.toBlob(resolve, file.file.type);
      };
    }) : file.file;

    const compressedFile = await new Promise(((resolve, reject) => {
      new Compressor(fileBlob, {
        quality: 0.3,
        maxWidth: 1280,
        success(file: Blob) {
          resolve(file);
        },
        error(error: Error) {
          reject(error)
        },
      });
    }))
    return new File([compressedFile as Blob], filename, {
      type: file.file.type.toLowerCase(),
    });
  });
  return await Promise.all(images);
};
