import { FC } from 'react';

import styles from './PatientInfo.module.scss';

interface PatientInfoProps {
  patient: {
    patientID: number | string;
    fullName: string;
    birthDate: string;
  };
}

const PatientInfo: FC<PatientInfoProps> = ({ patient }) => {

  const { patientID, fullName, birthDate } = patient;

  return (
    <div className={styles.info}>
      <div>
        <b>ID: </b>
        <span>{patientID}</span>
      </div>
      <div>
        <b>Пацієнт: </b>
        <span>{fullName}</span>
      </div>
      <div>
        <b>День народження: </b>
        <span>{birthDate}</span>
      </div>
    </div>
  );
};

export default PatientInfo;
