import { FC } from 'react';

import { Menu } from 'antd';
import Title from 'antd/lib/typography/Title';
import {
  CameraOutlined,
  DownloadOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

import useStore from '../../../hooks/useStore';
import { DocumentsList, Nav, PatientInfo } from '../../../components';
import { IDocument } from '../../../store/documents';

interface PatientPhoneProps {
  documents: IDocument[];
  onPhotoClick: () => void;
  onUploadPhotosClick: () => void;
  onBackClick: () => void;
}

const PatientPhone: FC<PatientPhoneProps> = ({
  documents,
  onPhotoClick,
  onUploadPhotosClick,
  onBackClick,
}) => {
  const { documentsStore } = useStore();

  return (
    <>
      <Title level={3} className={'title'}>
        Перегляд документів
      </Title>
      <Nav
        beforeButtons={[
          {
            title: 'Назад',
            icon: <RollbackOutlined />,
            onClick: () => onBackClick(),
          },
        ]}
        afterButtons={[
          {
            title: 'Фото',
            onClick: onPhotoClick,
            overlay: (
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<CameraOutlined />}
                  onClick={onPhotoClick}
                >
                  Зробити фото
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<DownloadOutlined />}
                  onClick={onUploadPhotosClick}
                >
                  Завантажити фото
                </Menu.Item>
              </Menu>
            ),
          },
        ]}
      />

      <div>
        <PatientInfo
          patient={{
            patientID: documentsStore.patient?.patientID || '',
            fullName: documentsStore.patient?.fullName || '',
            birthDate: documentsStore.patient?.birthDate || '',
          }}
        />
        <Title level={4} className="title">
          Документи
        </Title>
        <DocumentsList documents={documents} />
      </div>
    </>
  );
};

export default PatientPhone;
