import axios from 'axios';
import { homeurl } from './baseurl';

export const FilesApi = {
  getAuthFileBlob: async (url: string, compressed: boolean = false) => {
    const { data } = await axios.get<Blob>(homeurl + url, {
      responseType: 'blob',
      params: compressed
        ? {
            height: 30,
          }
        : undefined,
    });
    return data;
  },
  getAuthFileUrl: async (url: string, compressed: boolean = false) => {
    const blob = await FilesApi.getAuthFileBlob(url, compressed);
    return URL.createObjectURL(blob);
  },
  getAuthFile: async (url: string) => {
    const blob = await FilesApi.getAuthFileBlob(url);
    const file = new File([blob], `image.${blob.type.split('/')[1]}`, {
      type: blob.type,
    });
    return file;
  },
};
