import React, { FC } from 'react';
import { GridDropZone } from 'react-grid-dnd';

import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';

import styles from './DndActions.module.scss';

const DndActions: FC = () => {
  return (
    <div className={styles.moveActions}>
      <GridDropZone
        className={styles.moveActionsDropZone}
        id="createNewCollection"
        boxesPerRow={1}
        rowHeight={1}
      >
        <div className={styles.moveActionsIcon}>
          <FileAddOutlined />
        </div>
        <div />
      </GridDropZone>
      <GridDropZone
        className={styles.moveActionsDropZone}
        id="delete"
        boxesPerRow={1}
        rowHeight={1}
      >
        <div className={styles.moveActionsIcon}>
          <DeleteOutlined />
        </div>
        <div />
      </GridDropZone>
    </div>
  );
};

export default DndActions;
