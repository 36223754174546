import React, { FC, useState } from 'react';

import { Button, Tabs } from 'antd';

import { PatientSearchData, StepProps } from '../../types';
import PatientSelect from './PatientSelect';
import PatientFormForOffer from './PatientFormForOffer';
import styles from './Patient.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { SavedPatient } from './index';

const { TabPane } = Tabs;

const PatientForOffer: FC<StepProps> = ({ setSearchData }) => {
  const [patient, setPatient] = useState<{
    patientID: number;
    fullname: string;
    birthDate: string;
  } | null>(null);

  const handleFormSubmit = (
    patientValues: Omit<PatientSearchData, 'patientID'>
  ) => {
    setSearchData((prev) => ({
      ...prev,
      patient: {
        ...patientValues,
        patientID:
          prev.patient && typeof prev.patient === 'object'
            ? prev.patient.patientID
            : null,
      },
    }));
  };

  const handlePatientSelect = (patient: SavedPatient) => {
    setPatient(patient);
    setSearchData((prev) => {
      if (prev.patient && typeof prev.patient === 'object') {
        prev.patient.patientID = patient.patientID;
      }
      return prev;
    });
  };

  const handleClearUserClick = () => {
    setPatient(null);
    setSearchData((prev) => {
      if (typeof prev.patient === 'number') {
        delete prev.patient;
        return prev;
      } else {
        prev.patient = prev.patient
          ? { ...prev.patient, patientID: null }
          : {
              patientID: null,
              name: '',
              email: '',
              phone: '',
              birthDate: '',
            };
        return prev;
      }
    });
  };

  return (
    <div>
      {patient && (
        <div className={styles.chosenPatient}>
          <Button
            type="primary"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={handleClearUserClick}
          />
          <h4>Обраний пацієнт:</h4>
          <span>{patient.patientID}</span>
          <span>{patient.fullname}</span>
          <span>{patient.birthDate}</span>
        </div>
      )}
      <Tabs
        size={'small'}
        defaultActiveKey="1"
      >
        <TabPane tab="Заповнити" key="manual">
          <PatientFormForOffer onSubmit={handleFormSubmit} />
        </TabPane>
        <TabPane tab="Пошук" key="find">
          <PatientSelect
            chosenPatientID={patient?.patientID}
            onSelect={handlePatientSelect}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PatientForOffer;
