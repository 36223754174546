import React, { FC, useEffect, useState } from 'react';

import { notification } from 'antd';

import styles from '../../Schedule.module.scss';

import { PatientsSearch, PatientsTable } from '../../../../components';
import { IPatient } from '../../../../store/patients';
import { SelectTypes } from '../../../../components/PatientsSearch';
import { PatientsApi } from '../../../../api/patients';
import { SavedPatient } from './index';

interface PatientSelectProps {
  onSelect: (patient: SavedPatient) => void;
  chosenPatientID: number | undefined;
}

const PatientSelect: FC<PatientSelectProps> = ({ onSelect, chosenPatientID }) => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState<IPatient[]>([]);

  useEffect(() => {
    return () => {
      setPatients([]);
    };
  }, []);

  const handleSearch = async (value: string, searchType: string) => {
    if (!value.trim()) {
      return false;
    }

    setLoading(true);

    try {
      switch (searchType) {
        case SelectTypes.NAME:
          setPatients(await PatientsApi.getByName(value));
          break;
        case SelectTypes.PHONE:
          setPatients(await PatientsApi.getByPhone(value));
          break;
        case SelectTypes.ID:
          setPatients(await PatientsApi.getById(value));
          break;
      }
    } catch (e) {
      notification.error({
        message: 'Пацієнта не знайдено',
      });
      setLoading(false);
    }

    setLoading(false);
  };

  const choosePatient = (patient: IPatient) => ({
    onClick: () => {
      onSelect({
        patientID: Number(patient.patientID),
        fullname: patient.fullName,
        birthDate: patient.birthDate,
      });
    },
  });

  return (
    <>
      <PatientsSearch onSearch={handleSearch} isLoading={loading} />
      <PatientsTable
        isLoading={loading}
        patients={patients}
        handleRowClick={choosePatient}
        rowClassName={(r) =>
          Number(r.patientID) === chosenPatientID ? styles.chosenRow : ''
        }
      />
    </>
  );
};

export default PatientSelect;
