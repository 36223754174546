import useStore from '../../../hooks/useStore';

const useDragDocuments = () => {
  const { documentsStore } = useStore();

  const handleDrag = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string | undefined
  ) => {

    if ((!targetId && sourceIndex === targetIndex) || !documentsStore.patient) {
      return;
    }

    const sourceCollectionUUID = sourceId;
    const targetCollectionUUID = targetId || sourceId;
    const targetCollection = documentsStore.collections.find(
      (collection) => collection[0].collectionUUID === targetCollectionUUID
    );
    const sourceCollection = documentsStore.collections.find(
      (collection) => collection[0].collectionUUID === sourceCollectionUUID
    );

    if (!targetCollection || !sourceCollection) {
      return;
    }

    const sourceDocument = sourceCollection[sourceIndex];

    const { collectionUUID, orderNumber, documentType, creationDate } =
      targetIndex === 0
        ? targetCollection[0]
        : targetCollection[targetIndex]
        ? targetId
          ? targetCollection[targetIndex]
          : {
              ...targetCollection[targetIndex],
              orderNumber:
                targetIndex > sourceIndex
                  ? targetCollection[targetIndex].orderNumber + 1
                  : targetCollection[targetIndex].orderNumber,
            }
        : {
            ...targetCollection[targetCollection.length - 1],
            orderNumber:
              targetCollection[targetCollection.length - 1].orderNumber + 1,
          };

    let newMetadataArray = sourceDocument.metadata.split('/');
    newMetadataArray[5] = collectionUUID;

    const movedDocument = {
      ...sourceDocument,
      collectionUUID,
      orderNumber,
      documentType,
      creationDate,
      metadata: newMetadataArray.join('/'),
    };

    let updatedCollection = targetId
      ? [...targetCollection]
      : [...sourceCollection];

    if (!targetId) {
      updatedCollection.splice(sourceIndex, 1);
      updatedCollection.splice(targetIndex, 0, movedDocument);
    } else {
      updatedCollection.splice(targetIndex, 0, movedDocument);
    }

    updatedCollection = updatedCollection
      .sort((a, b) => a.orderNumber - b.orderNumber)
      .map((document, index) => ({ ...document, orderNumber: index }));

    (async () => {
      if (!documentsStore.patient) return;

      await documentsStore.rearrangeDocument({
        editDocumentData: {
          metadata: sourceDocument.metadata,
          newCollectionUUID: collectionUUID,
          documentType,
          creationDate,
          orderNumber,
        },
        updatedCollection,
        sourceDocument,
      });
    })();
  };

  return {
    handleDrag,
  };
};

export default useDragDocuments;
