import React, { FC } from 'react';

import styles from './GalleryNavigation.module.scss';

import GalleryNavigationItem from './GalleryNavigationItem';
import { IDocument } from '../../../store/documents';
import useGalleryScrollFlip from '../hooks/useGalleryScrollFlip';

interface GalleryNavigationProps {
  setPrevDocument: () => void;
  setNextDocument: () => void;
  documents: IDocument[];
  currentIndex: number;
  setActiveDocument: (index: number) => void;
}

const GalleryNavigation: FC<GalleryNavigationProps> = ({
  setNextDocument,
  setPrevDocument,
  documents,
  currentIndex,
  setActiveDocument,
}) => {
  const containerRef = useGalleryScrollFlip({
    setPrevDocument,
    setNextDocument,
  });

  return (
    <>
      {!!documents.length ? (
        <ul className={styles.galleryNavigation} ref={containerRef}>
          {documents.map((document, index) => (
            <GalleryNavigationItem
              key={document.filename}
              document={document}
              isActive={index === currentIndex}
              setActive={() => setActiveDocument(index)}
            />
          ))}
        </ul>
      ) : (
        <div />
      )}
    </>
  );
};

export default GalleryNavigation;
