import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

import Progress from 'antd/lib/progress';
import { HourglassOutlined } from '@ant-design/icons';

import styles from './CreateDocumentProgress.module.scss';

import useStore from '../../hooks/useStore';

const CreateDocumentProgress: FC = () => {
  const { documentsStore } = useStore();

  const resolvedQueueDocs = documentsStore.createDocumentRequestQueue.filter(
    (el) => !el.resolved
  );

  const isWaiting = documentsStore.createDocumentRequestQueue.some(
    (el) => el.networkWaiting
  );
  const error = documentsStore.createDocumentRequestQueue.find(
    (el) => el.errorStatus
  )?.errorStatus;
  const color = isWaiting ? '#ffa500' : !!error ? '#ff0000' : undefined;
  const percent = Math.ceil(
    resolvedQueueDocs.reduce((prev, current) => current.percent + prev, 0) /
      resolvedQueueDocs.length
  );

  return (
    <>
      <Progress
        className={classNames(styles.progress, {
          [styles.hide]: !error && !(percent === 0) && !percent && !isWaiting,
        })}
        type="circle"
        strokeColor={color}
        format={(percent) => {
          if (isWaiting)
            return <HourglassOutlined className={styles.waitingIcon} />;
          if (!!error) return <span style={{ color }}>{error}</span>;
          return percent + '%';
        }}
        percent={!!error ? 100 : percent === 0 || percent ? percent : 100}
        width={isMobile ? 50 : 70}
      />
    </>
  );
};

export default observer(CreateDocumentProgress);
